import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import {
  API_BASE,
  ROLES,
  PERMISSIONS,
  DEFAULT_USER_ROLE,
} from '../../constants/general.constants';
import { Observable, Subject, of } from 'rxjs';
import { combineLatestWith, map, catchError, tap } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { toSignal } from '@angular/core/rxjs-interop';
import {
  GlobalRole,
  Permission,
  PermissionId,
} from '../../interfaces/global-role.interface';
import { AuthenticationService } from '../authentication/authentication.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  private permissionsSource = new Subject<Permission[]>();
  private rolesSource = new Subject<GlobalRole[]>();

  permissions$ = this.permissionsSource.asObservable();
  roles$ = this.rolesSource.asObservable();

  permissions = toSignal<Permission[], Permission[]>(this.permissions$, {
    initialValue: [],
  });
  roles = toSignal<GlobalRole[], GlobalRole[]>(this.roles$, {
    initialValue: [],
  });

  private isAuthorized = false;

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(AuthenticationService) private authService: AuthenticationService
  ) { }

  private checkAuthentication(): boolean {
    if (!this.authService.isAuthenticated()) {
      //this.router.navigate(['/not-authenticated']);
      return false;
    }
    return true;
  }

  getRoles(): Observable<GlobalRole[]> {
    if (!this.checkAuthentication()) {
      return of([]);
    }

    if (this.isAuthorized) {
      return of(this.roles());
    }

    return this.http.get<GlobalRole[]>(`${API_BASE}${ROLES}`).pipe(
      tap((roles) => {
        this.rolesSource.next(roles);
        this.isAuthorized = true;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.isAuthorized = false;
          //this.router.navigate(['/not-authenticated']);
        }
        return of([]);
      })
    );
  }

  getPermissions(): Observable<Permission[]> {
    if (!this.checkAuthentication()) {
      return of([]);
    }

    if (this.isAuthorized) {
      return of(this.permissions());
    }

    return this.http.get<Permission[]>(`${API_BASE}${PERMISSIONS}`).pipe(
      tap((permissions) => {
        this.permissionsSource.next(permissions);
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.isAuthorized = false;
          //this.router.navigate(['/not-authenticated']);
        }
        return of([]);
      })
    );
  }

  roleWithId(roleId: number): GlobalRole | undefined {
    return this.roles().find((role) => role.roleId === roleId);
  }

  roleHasPermission(
    role: GlobalRole | undefined,
    permissionId: PermissionId
  ): boolean {
    return role?.permissions.includes(permissionId) || false;
  }

  getUserRoleWithPermissions(user: any): Observable<any> {
    if (!this.checkAuthentication()) {
      return of({});
    }

    return this.roles$.pipe(
      combineLatestWith(this.permissions$),
      map(([roles, permissions]) => {
        const userRole =
          roles.find(({ roleId }) => roleId === user.roleId) ||
          DEFAULT_USER_ROLE;
        const userPermissions = permissions
          .filter(
            ({ id }) =>
              typeof userRole !== 'string' && userRole.permissions.includes(id)
          )
          .map(({ name }) => name);

        return {
          [typeof userRole !== 'string' ? userRole.name : 'default']:
            userPermissions,
        };
      })
    );
  }
}
