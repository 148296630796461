import { Injectable } from '@angular/core';
import { GeneralHelpers } from '../../../../shared/helpers/general.helper';

import Module from '../../../../../assets/local_wasm/search-wasm/search-wasm';
import { ToastrService } from 'ngx-toastr';
import { ExecutionContext } from '../../../interfaces/chunk/chunk-context.interface';
import { tableFromIPC, tableToIPC } from 'apache-arrow';


@Injectable({
  providedIn: 'root',
})
export class WasmSearchService {
  // C API functions
  private doSearchWrapped: any;
  private loadAutoCompleteDataFromTableWrapped: any;
  private ac_loaded = false;
  constructor(private toastrService: ToastrService) {
    this.initStoireMethods();
  }

  private initStoireMethods() {
    // Store C API functions
    this.doSearchWrapped = Module.cwrap('DoSearch', 'number', [
      'number',
      'number',
    ]);
    this.loadAutoCompleteDataFromTableWrapped = Module.cwrap(
      'LoadAutoCompleteDataFromTable',
      'number',
      ['number', 'number', 'number', 'number']
    );
  }

  // ─────────────────────────────────────────────────────────────────────────────
  // Private methods
  // ─────────────────────────────────────────────────────────────────────────────

  // Create a null-terminated C string from a JS string
  private makeCString(key: string) {
    const key_str = new Uint8Array(GeneralHelpers.toUTF8Array(key + '\0')); // null-terminated string for C
    const bytes_per_element = key_str.BYTES_PER_ELEMENT;
    const key_ptr = Module._malloc(key_str.length * bytes_per_element);
    Module.HEAPU8.set(key_str, key_ptr / bytes_per_element);
    return key_ptr;
  }

  // Calculate the length of a null-terminated C string
  private getCStringLen(str_ptr: number) {
    let str_len = 0;
    while (Module.HEAPU8[str_ptr + str_len] != 0) {
      str_len++;
    }
    return str_len;
  }

  private loadIndexFromBuffer(buffer: any, index_type: any, context: ExecutionContext) {
    const bytes_per_element = buffer.BYTES_PER_ELEMENT;
    const array_ptr = Module._malloc(buffer.length * bytes_per_element);
    Module.HEAPU8.set(buffer, array_ptr / bytes_per_element);
    const column_name_ptr = this.makeCString('name');
    if (index_type == 'auto_complete') {
      const exact_match = false;
      if (
        this.loadAutoCompleteDataFromTableWrapped(
          column_name_ptr,
          exact_match,
          array_ptr,
          buffer.length
        ) == -1
      ) {
        context.addMessage('Error putting array', 'danger');
      }
    } else {
      const exact_match = true;
      if (
        this.loadAutoCompleteDataFromTableWrapped(
          column_name_ptr,
          exact_match,
          array_ptr,
          buffer.length
        ) == -1
      ) {
        context.addMessage('Error putting array', 'danger');
      }
    }
    Module._free(array_ptr);
    Module._free(column_name_ptr);
  }

  // ─────────────────────────────────────────────────────────────────────────────
  // Public methods
  // ─────────────────────────────────────────────────────────────────────────────

  public doSearch(data: any[], context: ExecutionContext) {
    if (
      !Array.isArray(data) ||
      data.length < 2 ||
      typeof data[0] !== 'string' ||
      typeof data[1] !== 'boolean'
    ) {
      context.addMessage('Invalid search parameters: Token must be a string and exact match must be a boolean.', 'danger');
      return;
    }

    if (!this.ac_loaded) {
      context.addMessage('Please load the auto-complete index first.', 'danger');
      return;
    }

    const token = data[0];
    const exact_match = data[1];

    const token_ptr = this.makeCString(token);
    let results_ptr = -1;
    try {
      results_ptr = this.doSearchWrapped(token_ptr, exact_match);
    } catch (error) {
      console.error('Error searching: ', error);
      context.addMessage('Search operation failed.', 'danger');
      this.toastrService.error('Search operation failed.');
      Module._free(token_ptr);
      return;
    }

    const results = this.parseResults(results_ptr);
    Module._free(token_ptr);
    Module._free(results_ptr);
    return results;
  }

  public async loadIndexData(data: any[], context: ExecutionContext) {
    if (
      !Array.isArray(data) ||
      data.length < 2 ||
      typeof data[0] !== 'string' ||
      typeof data[1] !== 'string'
    ) {
      context.addMessage('Invalid index data format: Data URL and index type must be strings.', 'danger');
      return;
    }

    const dataUrl = data[0];
    const index_type = data[1];

    if (!['auto_complete', 'some_other_type'].includes(index_type)) {
      context.addMessage('Invalid index type specified.', 'danger');
      return;
    }

    try {
      let buffer: Uint8Array;
      if (GeneralHelpers.canBeParsedToNumberArray(dataUrl)) {
        const numberArray = dataUrl.split(',').map(Number);
        buffer = new Uint8Array(numberArray);
      } else {
        buffer = await this.loadImageToBuffer(dataUrl);
      }
      
      // This should be unnecessary to load first into ArrowJS, but it's not working without it
      const arrowBuffer = tableToIPC(tableFromIPC(buffer));
      this.loadIndexFromBuffer(arrowBuffer, index_type, context);
      this.ac_loaded = true;
      console.log("Loaded " + buffer.length + " bytes of data");
    } catch (error) {
      console.error('Error loading index data:', error);
      context.addMessage(`Failed to load index data: ${error}`, 'danger');
    }
  }

  private parseResults(results_ptr: number): any {
    if (!results_ptr) {
      return null;
    }
    const results_len = this.getCStringLen(results_ptr);
    const results_array = new Uint8Array(
      Module.HEAP32.buffer,
      results_ptr,
      results_len
    );
    const results_json = new TextDecoder().decode(new Uint8Array(results_array));
    try {
      return JSON.parse(results_json);
    } catch (error) {
      console.error('Error parsing search results: ', error);
      return null;
    }
  }

  private async loadImageToBuffer(fileUrl: string): Promise<Uint8Array> {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve(new Uint8Array(reader.result as ArrayBuffer));
        };
        reader.onerror = () => {
          reject(new Error('Error reading image.'));
        };
        reader.readAsArrayBuffer(blob);
      });
    } catch (error) {
      throw new Error(`Error fetching image: ${error}`);
    }
  }
}
