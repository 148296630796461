import { Environment } from '../app/shared/interfaces/environment.interface';

export const environment: Environment = {
  production: true,
  apiUrl: 'ENLIGHTEN_API_URL',
  wsApiUrl: 'ENLIGHTEN_WEBSOCKET_URL',
  origin: window.location.origin,
  authProvider: 'ENLIGHTEN_AUTH_PROVIDER', // Can be 'auth0' or 'zitadel'
  adminWsUrl: 'ENLIGHTEN_WEBSOCKET_URL',
  sam2WebsocketUrl: 'ENLIGHTEN_SAM2_WEBSOCKET_URL',
  auth: {
    auth0: {
      domain: 'ENLIGHTEN_AUTH0_DOMAIN',
      clientId: 'ENLIGHTEN_AUTH0_CLIENT_ID',
      redirectUri: window.location.origin,
      audience: 'ENLIGHTEN_AUTH0_AUDIENCE',
      cacheLocation: 'localstorage',
      useRefreshTokens: true,
    },
    zitadel: {
      allowedUrls: ['ENLIGHTEN_API_URL'],
      projectId: 'ENLIGHTEN_ZITADEL_PROJECT_ID',
      issuer: 'ENLIGHTEN_ZITADEL_ISSUER',
      clientId: 'ENLIGHTEN_ZITADEL_CLIENT_ID',
      redirectUri: window.location.origin + '/login',
      postLogoutRedirectUri: window.location.origin + '/logout',
      requireHttps: true,
      showDebugInformation: false,
    },
  },
  pyodideVersion: 'v0.27.0a2',
  // pyodideVersion: 'v0.27.0',
  newtonUrl: 'ENLIGHTEN_NEWTON_URL',
};
