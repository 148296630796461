import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { FileUploadValidators } from '@iplab/ngx-file-upload';
import { ProjectService } from '../../services/project/project.service';
import { Dialog } from '../../interfaces/create-dialog.interface';
import * as zip from '@zip.js/zip.js';
import { AppStateService } from '../../services/app-state/app-state.service';
import { ToastrService } from 'ngx-toastr';
import { AutoUnsubscribe } from '../../decorators/auto-unsubscribe.decorator';

// Define an interface to represent project version details
interface Version {
  versionId: number; // Unique ID of the version
  majorVersion: number; // Major version number
  minorVersion: number; // Minor version number
}

/**
 * Component for importing a project from a zip file.
 * This component handles the project import process, including file selection,
 * data extraction from zip files, and project creation.
 * The user is guided through multiple steps, including selecting versions,
 * defining connections, and entering project name information.
 */
@AutoUnsubscribe()
@Component({
  selector: 'app-import-project-dialog',
  templateUrl: './import-project-dialog.component.html',
  styleUrls: ['./import-project-dialog.component.scss'],
})
export class ImportProjectDialogComponent implements OnDestroy {
  // Tracking the current step of the import process (e.g., file upload, version selection)
  public importStep = 1;
  public importInProgress = false; // Flag to track if import is in progress
  public importComplete = false; // Flag to track if import is complete
  public projectName = ''; // Holds the name of the imported project
  public versions: Version[] = []; // List of available project versions
  public selectedVersionId: number | null = null; // ID of the selected version
  public connections: { name: string; secret: string }[] = []; // List of connection objects
  public warnings: string[] = []; // List of warnings that arise during the import process

  // Forms to handle file upload, project name, and connection secrets
  fileUploadForm: FormGroup;
  projectNameForm: FormGroup;
  connectionsForm: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Dialog, // Injected data used to pass info into the dialog
    private dialogRef: MatDialogRef<ImportProjectDialogComponent>, // Reference to the dialog instance
    private formBuilder: FormBuilder, // Form builder for easy form creation
    private projectService: ProjectService, // Service for handling project import functionality
    private appStateService: AppStateService, // Service for managing application state
    private toastr: ToastrService // Service for displaying toast notifications
  ) {
    // Initialize the forms with their respective controls and validators
    this.fileUploadForm = this.formBuilder.group({
      files: new FormControl<File[]>(null as any, [
        FileUploadValidators.filesLimit(1), // Limit to 1 file
        FileUploadValidators.accept(['.zip']), // Only allow .zip files
      ]),
    });
    this.projectNameForm = this.formBuilder.group({
      projectName: ['', Validators.required], // Project name field is required
    });
    this.connectionsForm = this.formBuilder.group({}); // Initialize empty connections form
  }

  // eslint-disable-next-line
  ngOnDestroy(): void {
    // This method is intentionally empty
  }

  /**
   * Handles the file selection process.
   * Extracts and processes the selected zip file to retrieve project data.
   */
  public async onFileSelected() {
    if (this.fileUploadForm.valid) {
      const file = this.fileUploadForm.get('files')?.value?.[0]; // Get selected file
      if (file) {
        try {
          await this.extractProjectData(file); // Extract data from the zip file
          this.moveToNextStep(); // Move to the next step in the import process
        } catch (error) {
          console.error('Error processing zip file:', error); // Log any error encountered during extraction
          // Display an error message to the user
        }
      }
    }
  }

  /**
   * Handles the version selection.
   * Moves to the next step after a version is selected.
   */
  public onVersionSelected(versionId: number | null) {
    if (versionId !== null) {
      this.selectedVersionId = versionId; // Set the selected version
      this.moveToNextStep(); // Move to the next step in the import process
    }
  }

  /**
   * Handles submission of connection secrets.
   * Updates the connection objects with secrets entered by the user.
   */
  public onConnectionsSubmitted() {
    Object.keys(this.connectionsForm.controls).forEach((key) => {
      const connection = this.connections.find((c) => c.name === key);
      if (connection) {
        connection.secret = this.connectionsForm.get(key)?.value; // Store the secret for the connection
      }
    });
    this.moveToNextStep(); // Move to the next step
  }

  /**
   * Handles the submission of the project name.
   * Ensures that the project name is unique before proceeding with the import.
   */
  public onProjectNameSubmitted() {
    if (this.projectNameForm.valid) {
      const projectName = this.projectNameForm.get('projectName')?.value;
      if (this.projectService.isProjectNameUnique(projectName)) {
        this.importProject(); // Proceed to import the project if the name is unique
      } else {
        this.projectNameForm.get('projectName')?.setErrors({ notUnique: true }); // Set error if name is not unique
      }
    }
  }

  /**
   * Moves to the next step in the import process based on the current step.
   * Logic handles conditional transitions between steps.
   */
  private moveToNextStep() {
    if (this.importStep === 1) {
      this.importStep =
        this.versions.length > 1 ? 2 : this.connections.length > 0 ? 3 : 4;
    } else if (this.importStep === 2) {
      this.importStep = this.connections.length > 0 ? 3 : 4;
    } else {
      this.importStep++;
    }
  }

  /**
   * Extracts project data from the selected zip file.
   * Processes various project files (metadata, project.json, versions.json, connections.json)
   * to populate the component's state.
   */
  private async extractProjectData(file: File) {
    const requiredFiles = [
      'metadata.json',
      'project.json',
      'versions.json',
      'connections.json',
    ];
    const reader = new zip.ZipReader(new zip.BlobReader(file));

    try {
      const entries = await reader.getEntries();
      const fileContents: { [key: string]: string } = {};

      for (const entry of entries) {
        if (requiredFiles.includes(entry.filename)) {
          if (entry.getData) {
            const writer = new zip.TextWriter();
            const content = await entry.getData(writer);
            fileContents[entry.filename] = content;
          } else {
            throw new Error(`Unable to read data from ${entry.filename}`);
          }
        }
      }

      // Validate all required files are present
      for (const requiredFile of requiredFiles) {
        if (!fileContents[requiredFile]) {
          throw new Error(`${requiredFile} not found in the zip file`);
        }
      }

      // Process metadata.json
      const metadata = JSON.parse(fileContents['metadata.json']);
      if (
        typeof metadata.format !== 'string' ||
        !/^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*$/.test(metadata.format) ||
        metadata.version_id !== 1
      ) {
        throw new Error('Invalid or unsupported project format');
      }

      // Process project.json
      const projectData = JSON.parse(fileContents['project.json']);
      this.projectName = projectData.name;
      this.selectedVersionId = projectData.currentVersionId;
      this.projectNameForm.patchValue({ projectName: this.projectName });

      // Process versions.json
      this.versions = JSON.parse(fileContents['versions.json']);
      this.versions.sort((a, b) => b.versionId - a.versionId);

      // Process connections.json
      const connectionsData = JSON.parse(fileContents['connections.json']);
      this.connections = connectionsData;
      this.initializeConnectionsForm();

      console.log('Extracted project data:', {
        projectName: this.projectName,
        selectedVersionId: this.selectedVersionId,
        versions: this.versions,
        connections: this.connections,
      });
    } catch (error) {
      console.error('Error extracting project data:', error);
      this.toastr.error(
        'Failed to extract project data. Please check the file and try again.'
      );
      throw error;
    } finally {
      await reader.close();
    }
  }

  private initializeConnectionsForm() {
    this.connectionsForm = this.formBuilder.group({});
    this.connections.forEach((conn) => {
      this.connectionsForm.addControl(
        conn.name,
        this.formBuilder.control('', Validators.required)
      );
    });
  }

  /**
   * Handles the import process by sending the extracted data to the backend.
   * Subscribes to the result and updates the UI accordingly.
   */
  private importProject() {
    this.importStep = 5; // Move to step 5: project import in progress
    this.importInProgress = true; // Set import in progress flag
    const file = this.fileUploadForm.get('files')?.value?.[0]; // Get the selected file
    const projectName = this.projectNameForm.get('projectName')?.value; // Get the entered project name
    const aliasSecrets = this.connections.reduce((acc, conn) => {
      acc[conn.name] = conn.secret; // Map connection names to their secrets
      return acc;
    }, {} as { [key: string]: string });

    if (file && this.selectedVersionId !== null) {
      this.projectService
        .importProject(file, projectName, this.selectedVersionId, aliasSecrets)
        .subscribe({
          next: (result) => {
            this.importInProgress = false; // Import is no longer in progress
            this.importComplete = true; // Mark import as complete
            this.warnings = result.warnings || []; // Capture any warnings
            this.importStep = 6; // Move to step 6: import complete
            this.appStateService.loadProjects().then(() => {
              this.appStateService.navigateToProject(
                result.projectId,
                result.currentVersionId
              );
              this.dialogRef.close();
            });
            result.warnings.forEach((warning: string) => {
              this.toastr.warning(warning, 'Warning', {
                timeOut: 0,
                extendedTimeOut: 0,
              });
            });
          },
          error: (error) => {
            this.importInProgress = false; // Reset progress flag on error
            console.error('Import failed:', error); // Log the error
            // Display error message to the user
          },
        });
    }
  }

  /**
   * Copies any warnings generated during the import process to the clipboard.
   */
  public copyWarnings() {
    const warningText = this.warnings.join('\n'); // Join warnings into a single string
    navigator.clipboard.writeText(warningText).then(() => {
      console.log('Warnings copied to clipboard'); // Log success
    });
  }

  /**
   * Closes the import dialog and returns the success status and warnings to the caller.
   */
  public closeDialog() {
    this.dialogRef.close({
      success: this.importComplete,
      warnings: this.warnings,
    });
  }
}
