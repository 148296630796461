import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
// Component
import { AliasManagerComponent } from './alias-manager/alias-manager.component';
import { DatabaseListComponent } from './database-list/database-list.component';
// Material
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';

// ────────────────────────────────────────────────────────────────────────────────
// Forms Module
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
// ────────────────────────────────────────────────────────────────────────────────
// Flex layout
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
// ────────────────────────────────────────────────────────────────────────────────
// Table Module
import { TableModule } from '../table/table.module';
// ────────────────────────────────────────────────────────────────────────────────
// Code Editor
import { CodeEditorModule } from '../code-editor/code-editor.module';
import { GuiGridModule } from '@generic-ui/ngx-grid';
import { MatTabsModule } from '@angular/material/tabs'
import { TableListModule } from '../table-list/table-list.module'
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  imports: [
    CommonModule,
    // Materail
    MatDialogModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatTabsModule,
    MatSelectModule,
    // Layout
    FlexLayoutModule,
    // Form
    ReactiveFormsModule,
    FormsModule,
    // Table
    TableModule,
    // Code Editor
    CodeEditorModule,
    TableListModule,
    GuiGridModule,
  ],
  declarations: [AliasManagerComponent, DatabaseListComponent],
  providers: [DatePipe],
})
export class ArrowAliasModule {}
