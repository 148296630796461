import { effect, inject, Injectable, signal } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, map } from 'rxjs';
import {
  PROJECT,
  API_BASE,
  ARROW,
} from '../../constants/general.constants';
import {
  ArrowAlias,
  ArrowAliasV2,
  CreateArrowAlias,
  SaveArrowAlias,
} from '../../interfaces/arrow-alias.interface';
import { AppStateService } from '../app-state/app-state.service';
import { isNonNullable } from '../../helpers/general.helper'
import { toObservable } from '@angular/core/rxjs-interop'

@Injectable({
  providedIn: 'root',
})
export class ArrowAliasService {
  #appState = inject(AppStateService);

  #aliasV2List = signal<ArrowAliasV2[]>([]);
  public aliasV2List = this.#aliasV2List.asReadonly();
  public aliasV2List$ = toObservable(this.#aliasV2List);

  constructor(private http: HttpClient) {
    effect(() => {
      const projectId = this.#appState.projectId();
      if (projectId > 0) {
        console.log('ArrowAliasService: projectId effect', this.#appState.projectId());
        this.getV2(this.#appState.projectId()).subscribe();
      }
    });
  }

  /** convience method to construct an aliasV2 from an alias */
  alias2V2(alias: ArrowAlias): ArrowAliasV2 | undefined {
    if (!alias.cloudBased || typeof alias.cloudConfig !== 'string') {
      return;
    }
    return {
      aliasId: alias.aliasId,
      projectId: alias.projectId,
      name: alias.name,
      url: alias.url,
      cloudConfig: JSON.parse(alias.cloudConfig),
      cloudConfigSecret: alias.cloudConfigSecret,
    };
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Create Arrow Alias

  create(data: CreateArrowAlias, projectId: string | any): Observable<object> {
    return this.http.post<ArrowAlias>(
      `${API_BASE}${PROJECT}/${projectId}/${ARROW}`,
      data
    )
    .pipe(
      map((res: ArrowAlias) => {
        const alias = this.alias2V2(res);
        if (alias) {
          this.#pushAliasV2(alias);
        }
        return res;
      })
    );
  }

  // ─────────────────────────────────────────────────────────────────────
  // Get

  get(
    projectId: any = this.#appState.projectId()
  ): Observable<ArrowAlias[] | any> {
    if (projectId === '' || projectId === null || projectId === undefined) {
      return new Observable<ArrowAlias[]>();
    }
    return this.http
      .get<ArrowAlias>(
        `${API_BASE}${PROJECT}/${projectId}/${ARROW}`
      )
      .pipe(
        map((res: any) => {
          if (res === null) {
            return;
          }
          const list = res.map((alias: ArrowAlias) => this.alias2V2(alias));
          const v2list = list.filter((alias: any) => alias !== undefined) as ArrowAliasV2[];
          this.#aliasV2List.set(v2list);
          return res;
        })
      );
  }

  getV2(projectId: number = this.#appState.projectId()): Observable<ArrowAliasV2[]> {
    return this.http
      .get<ArrowAlias[]>(
        `${API_BASE}${PROJECT}/${projectId}/${ARROW}`
      )
      .pipe(
        map((res: ArrowAlias[]) => {
          const list = res.map((alias: ArrowAlias) => this.alias2V2(alias));
          const v2list = list.filter(isNonNullable) as ArrowAliasV2[];
          this.#aliasV2List.set(v2list);
          return v2list;
        })
      );
  }

  // ─────────────────────────────────────────────────────────────────────
  // Get by id

  getById(
    projectId: string | any,
    aliasId: string | any
  ): Observable<ArrowAlias> {
    return this.http.get<ArrowAlias>(
      `${API_BASE}${PROJECT}/${projectId}/${ARROW}/${aliasId}`
    );
  }

  /// duplicates the specified alias and returns the new alias after adding it to both lists
  public duplicate(
    projectId: string | any,
    aliasId: string | any
  ): Observable<ArrowAlias> {
    return this.http.put<ArrowAlias>(
      `${API_BASE}${PROJECT}/${projectId}/${ARROW}/${aliasId}/duplicate`,
      {}
    )
    .pipe(
      map((res: ArrowAlias) => {
        const alias = this.alias2V2(res);
        if (alias) {
          this.#pushAliasV2(alias);
        }
        return res;
      })
    );
  }

  // ─────────────────────────────────────────────────────────────────────
  // Update

  public update(
    projectId: string | any,
    aliasId: string | any,
    data: SaveArrowAlias,
  ): Observable<object> {
    return this.http.put<ArrowAlias>(
      `${API_BASE}${PROJECT}/${projectId}/${ARROW}/${aliasId}`,
      data
    )
    .pipe(
      map((res: ArrowAlias) => {
        const v2list = this.#aliasV2List();
        const idx2 = v2list.findIndex((a) => a.aliasId === aliasId);
        if (idx2 !== -1) {
          v2list[idx2] = this.alias2V2(res)!;
          this.#aliasV2List.set(v2list);
        }
        return res;
      })
    );
  }

  // ─────────────────────────────────────────────────────────────────────
  // Delete

  public delete(
    projectId: string | any,
    aliasId: string | any
  ): Observable<object> {
    return this.http.delete<ArrowAlias>(
      `${API_BASE}${PROJECT}/${projectId}/${ARROW}/${aliasId}`
    )
    .pipe(
      map((res: any) => {
        let v2list = this.#aliasV2List();
        v2list = v2list.filter((a) => a.aliasId !== aliasId);
        this.#aliasV2List.set(v2list);
        return res;
      })
    );
  }

  #pushAliasV2(alias: ArrowAliasV2) {
    const list = this.#aliasV2List();
    list.push(alias);
    this.#aliasV2List.set(list);
  }
}
