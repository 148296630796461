<div
  class="code-editor-wrapper w100"
  [ngClass]="{ 'read-only': readOnly }"
  fxLayout="column"
  fxLayoutAlign="start start"
  fxLayoutGap="10px"
  (click)="touchEditor()"
>
  <div
    class="toolbar"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="10px"
  >
    <ng-container *ngFor="let item of toolbarContent">
      <button
        type="button"
        *ngIf="showToolbar"
        class="small"
        mat-icon-button
        [attr.color]="item.color"
        [attr.aria-label]="item.aria"
        [matTooltip]="item.tooltip"
        (click)="processToolbarCommand(item.action)"
        [attr.test-id]="item.testId"
        [disabled]="readOnly"
      >
        <mat-icon>{{ item?.icon }}</mat-icon>
      </button>
    </ng-container>
  </div>
  <ngx-codemirror
    class="code-editor w100"
    [(ngModel)]="content"
    [options]="options"
    (ngModelChange)="handleChange($event)"
  ></ngx-codemirror>
</div>
