import { NgModule } from '@angular/core';
import { AvatarFallbackDirective } from './avatar-fallback.directive';
import { ClickOutsideDirective } from './click-outside.directive';


@NgModule({
  declarations: [
    AvatarFallbackDirective,
    ClickOutsideDirective,
  ],
  exports: [
    AvatarFallbackDirective,
    ClickOutsideDirective,
  ]
})
export class DirectivesModule { }
