import { DataType, Field, Table, Type } from 'apache-arrow';

export type FieldInfo = {
  name: string;
  typeId: number;
  typeName: string;
  typeObject: any;
  nullable: boolean;
}

export type RowValue = string | number | boolean | null;

export class ArrowHelper {
  static fieldToJson(field: Field): FieldInfo {
    return {
      name: field.name,
      typeId: field.type.typeId as number,
      typeName: Type[field.type.typeId as number],
      typeObject: field.type,
      nullable: field.nullable,
      }
  }

  static tableToJson(table: Table) {
    const vals = [];
    for (let i = 0; i < table.numCols; i++) {
      vals.push(this.columnValuesToJson(table, i, 0, table.numRows));
    }
    return {
      numRows: table.numRows,
      numCols: table.numCols,
      schema: table.schema.fields.map(f => ArrowHelper.fieldToJson(f)),
      columns: vals,
    }
  }

  static tableRangeToJson(table: Table, offset: number, count: number) {
    if (offset < 0 || count < 0 || offset + count > table.numRows) {
      return null;
    }
    const vals = [];
    for (let i = 0; i < table.numCols; i++) {
      vals.push(this.columnValuesToJson(table, i, offset, count));
    }
    return {
      numRows: table.numRows,
      numCols: table.numCols,
      schema: table.schema.fields.map(f => ArrowHelper.fieldToJson(f)),
      columns: vals,
    }
  }

  static columnValuesToJson(table: Table, colIndex: number, offset: number, count: number): RowValue[] {
    const vector = table.getChildAt(colIndex);
    if (!vector) {
      return [];
    }
    const values: RowValue[] = [];
    for (let i = offset; i < offset + count; i++) {
      if (!vector.isValid(i)) {
        values.push(null);
        continue;
      }
      if (typeof vector.get(i) === 'bigint') {
        values.push(vector.get(i).toString());
        continue;
      }
      if (DataType.isTimestamp(vector.type)) {
        values.push(new Date(vector.get(i)).toISOString());
        continue;
      }
      if (DataType.isInt(vector.type) || DataType.isFloat(vector.type)) {
        values.push(vector.get(i));
        continue;
      }
      values.push(vector.get(i).toString());
    }
    return values;
  }
}
