import { Injectable, inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, EMPTY, firstValueFrom } from 'rxjs';
import {
  API_BASE,
  PROJECT,
  VERSION,
  BOOK,
  FILE_TYPE,
  MIME_TYPES,
} from '../../constants/general.constants';
import { AppStateService } from '../app-state/app-state.service';
import { NotebookDefaultFile } from '../../interfaces/file.interface';
import { GeneralHelpers } from '../../helpers/general.helper';
import { Papa } from 'ngx-papaparse';
import { ExecutionContext } from '../../interfaces/chunk/chunk-context.interface';

@Injectable({
  providedIn: 'root',
})
export class NotebookDefaultFilesService {
  #appState = inject(AppStateService);

  constructor(private http: HttpClient, private papa: Papa) { }

  /**
   * Get all default files for a specific notebook
   * @param projectId - The project ID
   * @param notebookId - The notebook ID
   * @returns Observable of NotebookDefaultFile array
   */
  public getDefaultFiles(
    projectId: number | null,
    notebookId: number | null
  ): Observable<NotebookDefaultFile[]> {
    if (!projectId || !notebookId) {
      return EMPTY;
    }

    return this.http.get<NotebookDefaultFile[]>(
      `${API_BASE}${PROJECT}/${projectId}/${VERSION}/${this.#appState.versionId()}/${BOOK}/${notebookId}/files`
    );
  }

  /**
   * Create a new default file for a notebook
   * @param projectId - The project ID
   * @param notebookId - The notebook ID
   * @param fileData - The file data to be uploaded
   * @returns Observable of the created NotebookDefaultFile
   */
  public createDefaultFile(
    projectId: number | null,
    notebookId: number | null,
    fileData: FormData
  ): Observable<NotebookDefaultFile> {
    if (!projectId || !notebookId) {
      return EMPTY;
    }

    return this.http.post<NotebookDefaultFile>(
      `${API_BASE}${PROJECT}/${projectId}/${VERSION}/${this.#appState.versionId()}/${BOOK}/${notebookId}/files`,
      fileData
    );
  }

  /**
   * Get contents of a specific default file
   * @param projectId - The project ID
   * @param notebookId - The notebook ID
   * @param fileId - The file ID
   * @param responseType - The expected response type
   * @returns Observable of the file content
   */
  public getDefaultFileContents(
    projectId: number | null,
    notebookId: number | null,
    fileId: string | null,
    responseType: 'text' | 'blob' | 'arraybuffer' | 'document' | 'json' = 'blob'
  ): Observable<any> {
    if (!projectId || !notebookId || !fileId) {
      return EMPTY;
    }

    return this.http.get(
      `${API_BASE}${PROJECT}/${projectId}/${VERSION}/${this.#appState.versionId()}/${BOOK}/${notebookId}/files/${fileId}`,
      { responseType: responseType as any }
    );
  }

  /**
   * Update contents of a specific default file
   * @param projectId - The project ID
   * @param notebookId - The notebook ID
   * @param fileId - The file ID
   * @param fileContent - The new file content
   * @returns Observable of the updated NotebookDefaultFile
   */
  public updateDefaultFileContents(
    projectId: number | null,
    notebookId: number | null,
    fileId: string | null,
    fileContent: Blob
  ): Observable<NotebookDefaultFile> {
    if (!projectId || !notebookId || !fileId) {
      return EMPTY;
    }

    return this.http.put<NotebookDefaultFile>(
      `${API_BASE}${PROJECT}/${projectId}/${VERSION}/${this.#appState.versionId()}/${BOOK}/${notebookId}/files/${fileId}`,
      fileContent,
      {
        headers: {
          'Content-Type': 'application/octet-stream',
        },
      }
    );
  }

  /**
   * Delete a specific default file
   * @param projectId - The project ID
   * @param notebookId - The notebook ID
   * @param fileId - The file ID
   * @returns Observable of the deletion response
   */
  public deleteDefaultFile(
    projectId: number | null,
    notebookId: number | null,
    fileId: string | null
  ): Observable<void> {
    if (!projectId || !notebookId || !fileId) {
      return EMPTY;
    }

    return this.http.delete<void>(
      `${API_BASE}${PROJECT}/${projectId}/${VERSION}/${this.#appState.versionId()}/${BOOK}/${notebookId}/files/${fileId}`
    );
  }

  /**
   * Save data as a default notebook file
   * @param data - Array containing [fileName, content]
   * @param chunkContext - Execution context for the chunk
   * @returns Promise that resolves with the saved file data
   */
  public async saveDefaultNotebookFile(
    data: any[],
    chunkContext: ExecutionContext
  ): Promise<NotebookDefaultFile | null> {
    // Set busy state
    chunkContext.setBusy(true, 'Saving default notebook file');

    try {
      // Validate input parameters
      if (!Array.isArray(data) || data.length < 2) {
        chunkContext.addMessage(
          'Invalid parameters: Expected [fileName, content]',
          'danger'
        );
        return null;
      }

      const [fileNameWithExtension, content] = data;

      if (!fileNameWithExtension || typeof fileNameWithExtension !== 'string') {
        chunkContext.addMessage('Invalid file name', 'danger');
        return null;
      }

      const fileType = GeneralHelpers.fileExtensionFromString(
        fileNameWithExtension
      );

      const projectId = this.#appState.projectId();
      const notebookId = this.#appState.notebookId();

      if (!projectId || !notebookId) {
        chunkContext.addMessage(
          'Project ID or Notebook ID is missing',
          'danger'
        );
        return null;
      }

      // Process content based on file type
      let fileContent: any;
      const mimeType = MIME_TYPES[fileType] || 'text/plain';

      try {
        switch (fileType) {
          case FILE_TYPE.csv:
            fileContent = this.papa.unparse(content);
            break;
          case FILE_TYPE.json:
          case FILE_TYPE.javascript:
          case FILE_TYPE.python:
            fileContent =
              typeof content === 'string'
                ? content
                : GeneralHelpers.jsonStringify(content);
            break;
          case FILE_TYPE.arrow:
          case FILE_TYPE.parquet:
            if (content instanceof Uint8Array) {
              fileContent = content;
            } else if (
              typeof content === 'string' &&
              GeneralHelpers.canBeParsedToNumberArray(content)
            ) {
              fileContent = GeneralHelpers.stringToUint8Array(content);
            } else {
              fileContent = content;
            }
            break;
          default:
            fileContent = content;
        }
      } catch (error) {
        console.error('Error processing file content:', error);
        chunkContext.addMessage(
          `Error processing file content: ${error}`,
          'danger'
        );
        return null;
      }

      // Create file and form data
      const file = new File([fileContent], fileNameWithExtension, {
        type: mimeType,
      });
      const formData = new FormData();
      formData.append('file', file);

      // Save the file
      const response = await firstValueFrom(
        this.createDefaultFile(projectId, notebookId, formData)
      );

      if (response) {
        chunkContext.addMessage('File saved successfully', 'success');
        return response;
      } else {
        chunkContext.addMessage('Failed to save file', 'danger');
        return null;
      }
    } catch (error) {
      console.error('Error saving default notebook file:', error);
      chunkContext.addMessage(`Error saving file: ${error}`, 'danger');
      return null;
    } finally {
      chunkContext.setBusy(false);
    }
  }
}
