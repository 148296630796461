import { Component, OnDestroy, OnInit, input, signal } from '@angular/core';
import { ArrowClient } from 'src/app/shared/services/arrow-websocket/arrow-client';
import { TableListColDef } from '../../table-list/table-list.component'
import { RawArrowTable } from '../arrow-schema.interface'
import { toObservable } from '@angular/core/rxjs-interop'
import { ArrowTypeMeta } from 'src/app/shared/modules/table-chunk/table-chunk.interface'
import { ExecutionContext } from 'src/app/shared/interfaces/chunk/chunk-context.interface'
import { Table } from 'apache-arrow'
import { ArrowHelper } from 'src/app/shared/helpers/arrow.helper'
import { AutoUnsubscribe } from 'src/app/shared/decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'table-explorer',
  templateUrl: './table-explorer.component.html',
  styleUrl: './table-explorer.component.scss'
})
export class TableExplorerComponent implements OnInit, OnDestroy {
  arrowClient = input.required<ArrowClient>();
  context = input.required<ExecutionContext>();
  tables = signal<RawArrowTable[]>([]);
  tables$ = toObservable(this.tables);
  selectedTable = signal<RawArrowTable | undefined>(undefined);
  protected schemaCache = new Map<string, ArrowTypeMeta[]>();
  selectedSchema = signal<ArrowTypeMeta[]>([]);
  schemas$ = toObservable(this.selectedSchema);

  ngOnInit(): void {
    this.arrowClient().listTables().then((tables) => {
      this.tables.set(tables as RawArrowTable[]);
    });

  }

  // eslint-disable-next-line
ngOnDestroy(): void {
    // intentionally empty
  }

  tableSelected(table: RawArrowTable | undefined) {
    this.selectedTable.set(table);
    if (table) {
      this.selectedSchema.set(this.schemaCache.get(table.name) || []);
    } else {
      this.selectedSchema.set([]);
    }
  }

  copyColumnsToClipboard() {
    const colNames = this.selectedSchema().map((col) => col.name).join(', ');
    navigator.clipboard.writeText(colNames).then(() => {
      this.context().addMessage('Columns copied to clipboard', 'info');
    }).catch((error) => {
      console.error('Failed to copy columns to clipboard', error);
      this.context().addMessage('Failed to copy columns to clipboard', 'danger');
    });
  }

  loadSchema() {
    if (!this.selectedTable()) {
      return;
    }
    const tableName = this.selectedTable()!.name;
    if (this.schemaCache.has(tableName)) {
      this.selectedSchema.set(this.schemaCache.get(tableName)!);
      return;
    }
    const query = `select * from ${tableName} limit 1`;
    this.context().setBusy(true, 'Loading schema');
    this.arrowClient().performQuery(undefined, query, true).then((data) => {
      const table = data as any as Table;
      const cdata: ArrowTypeMeta[] = [];
      for (const col of table.schema.fields) {
        const colData = ArrowHelper.fieldToJson(col);
        cdata.push(colData);
      }
      this.schemaCache.set(tableName, cdata);
      this.selectedSchema.set(cdata);
    }).catch((error) => {
      console.error('Failed to load schema', error);
      this.context().addMessage('Failed to load schema', 'danger');
    }).finally(() => {
      this.context().setBusy(false, '');
    });
  }

  deleteTable() {
    const table = this.selectedTable();
    if (!table) {
      return;
    }
    if (confirm(`Are you sure you want to delete the table '${table.name}'?`)) {
      console.log('Deleting table', table);
      this.context().setBusy(true, `Deleting table '${table.name}'`);
      this.arrowClient().deleteTable(table.name).then(() => {
        this.context().addMessage(`Table '${table.name}' deleted`, 'info');
        this.tables.set(this.tables().filter((t) => t.name !== table.name));
        this.selectedTable.set(undefined);
      }).finally(() => {
        this.context().setBusy(false, '');
      });
    }
  }

  protected schemaListColumns: TableListColDef<ArrowTypeMeta>[] = [
    { header: 'Column', field: 'name' },
    { header: 'Type', field: 'typeName' },
    { header: 'Nullable', field: 'nullable', checkbox: true },
  ];
  protected tableListColumns: TableListColDef<RawArrowTable>[] = [
    { header: 'Table', field: 'name' },
  ];
}
