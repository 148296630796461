import { Component, effect, input, OnDestroy, signal } from '@angular/core';
import { ArrowField, ArrowSchema } from '../arrow-schema.interface';
import { TableListColDef } from '../../table-list/table-list.component'
import { toObservable } from '@angular/core/rxjs-interop'
import { AutoUnsubscribe } from '../../../decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'arrow-schema-viewer',
  templateUrl: './schema-viewer.component.html',
  styleUrl: './schema-viewer.component.scss'
})
export class SchemaViewerComponent implements OnDestroy {
  schema = input.required<ArrowSchema>();
  _fields = signal<ArrowField[]>([]);
  fields$ = toObservable(this._fields);

  protected schemaListColumns: TableListColDef<ArrowField>[] = [
    { header: 'Column', field: 'name' },
    { header: 'Type', field: 'type' },
    { header: 'Nullable', field: 'nullable', checkbox: true },
  ];

  constructor() {
    effect(() => {
      this._fields.set(this.schema().fields);
    }, { allowSignalWrites: true });
  }

 // eslint-disable-next-line
 ngOnDestroy() {
    // This is intentional
  }
}
