import { inject, Injectable } from '@angular/core';

import {
  API_BASE,
  BOOK,
  CACHE,
  FILES,
  NOTEBOOK_FILE,
  PROJECT,
  RELOAD_NOTEBOOK_FILES,
  UPDATE_PANEL_STATE_FROM_CACHE,
  VERSION,
} from '../../constants/general.constants';

import { catchError, EMPTY, from, map, Observable, of, switchMap, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { Cache, CacheContent } from '../../interfaces/cache.interface';
import { GeneralHelpers } from '../../helpers/general.helper';
import { ToastrService } from 'ngx-toastr';
import { NotebookFile } from '../../interfaces/file.interface';

import { MessageService } from '../message/message.service';
import { FileDataService } from '../file-data/file-data.service';
import { LocalstorageHelper } from '../../helpers/localstorage.helper';
import { ERROR_SAVE_PROJECT_FILE } from '../../constants/additional-methods.constants';
import { ExecutionContext } from '../../interfaces/chunk/chunk-context.interface';
import { AppStateService } from '../app-state/app-state.service';
import { TruncationHelper } from '../../helpers/truncation.helper';

@Injectable({
  providedIn: 'root',
})
export class CacheService {
  #appState = inject(AppStateService);
  public notebookCacheRaw!: Cache;

  public notebookCacheContent!: CacheContent;

  constructor(
    private http: HttpClient,
    private toastrService: ToastrService,
    private messageService: MessageService,
    private fileDataService: FileDataService
  ) { }

  // ─────────────────────────────────────────────────────────────────────
  // Create cache

  public create(
    data: CacheContent,
    notebookId: string | null = this.#appState.notebookId().toString()
  ): Observable<object> {
    const projectId = this.#appState.projectId();
    const versionId = this.#appState.versionId();

    if (!projectId || !versionId || !notebookId) {
      this.toastrService.error('Invalid project or version or notebook id');
      return new Observable();
    }

    return this.http
      .post<Cache>(
        `${API_BASE}${PROJECT}/${projectId}/${VERSION}/${versionId}/${BOOK}/${notebookId}/${CACHE}`,
        data
      )
      .pipe(
        map((res: Cache | any) => {
          if (res === null) {
            return;
          }
          this.setNotebookCache(res);
          return res;
        })
      );
  }

  // ────────────────────────────────────────────────────────────────────────────────
  // Get cache

  public get(
    notebookId: string | any = this.#appState.notebookId()
  ): Observable<Cache> {
    const projectId = this.#appState.projectId();
    const versionId = this.#appState.versionId();
    return this.http
      .get<Cache>(
        `${API_BASE}${PROJECT}/${projectId}/${VERSION}/${versionId}/${BOOK}/${notebookId}/${CACHE}`
      )
      .pipe(
        map((res: Cache | any) => {
          if (res === null) {
            return;
          }
          this.setNotebookCache(res);
          return res;
        })
      );
  }

  public setNotebookCache(data: Cache): void {
    LocalstorageHelper.setCahceId(data.cacheId);
    this.notebookCacheRaw = data;

    if (!data.content || data.content === '') {
      // Initialize with default structure if content is empty
      const initialContent: CacheContent = {
        state: [],
        version: 1,
        panels: {
          manageBlock: false,
          notebookVarList: false,
          snapshotList: false,
          notebookFiles: false,
          projectList: false,
          moduleList: false,
          projectFiles: false,
          projectVarList: false,
          connections: false,
          versionList: false,
          featureList: false,
        },
      };
      this.notebookCacheContent = initialContent;
      this.notebookCacheRaw.content = JSON.stringify(initialContent);
    } else if (GeneralHelpers.isJSON(data.content)) {
      const content = GeneralHelpers.jsonParse(data.content);
      this.notebookCacheContent = {
        state: this.processState(content.state || []),
        version: content.version || 1,
        panels: {
          manageBlock: content.panels?.manageBlock ?? false,
          notebookVarList: content.panels?.notebookVarList ?? false,
          snapshotList: content.panels?.snapshotList ?? false,
          notebookFiles: content.panels?.notebookFiles ?? false,
          projectList: content.panels?.projectList ?? false,
          moduleList: content.panels?.moduleList ?? false,
          projectFiles: content.panels?.projectFiles ?? false,
          projectVarList: content.panels?.projectVarList ?? false,
          connections: content.panels?.connections ?? false,
          versionList: content.panels?.versionList ?? false,
          featureList: content.panels?.featureList ?? false
        },
      };
    } else {
      console.error('Invalid cache content format');
      this.notebookCacheContent = {
        state: [],
        version: 1,
        panels: {
          manageBlock: true,
          notebookVarList: true,
          snapshotList: true,
          notebookFiles: true,
          projectList: true,
          moduleList: true,
          projectFiles: true,
          projectVarList: true,
          connections: true,
          versionList: true,
          featureList: true,
        },
      };
    }

    this.notebookCacheRaw.content = JSON.stringify(this.notebookCacheContent);

    if (this.notebookCacheContent && 'panels' in this.notebookCacheContent) {
      this.messageService.sendMessage(
        UPDATE_PANEL_STATE_FROM_CACHE,
        this.notebookCacheContent.panels
      );
    }
  }

  private processState(state: any[]): any[] {
    return state.map(item => {
      if (item.lastOutput) {
        if (typeof item.lastOutput === 'object') {
          return {
            ...item,
            lastOutput: {
              value: TruncationHelper.truncateOutput(item.lastOutput.value),
              resultStatus: item.lastOutput.resultStatus
            }
          };
        } else {
          return {
            ...item,
            lastOutput: TruncationHelper.truncateOutput(item.lastOutput)
          };
        }
      }
      return item;
    });
  }

  public getNotebookCache(): CacheContent | any {
    return this.notebookCacheContent;
  }

  public getNotebookCacheRaw(): Cache | any {
    return this.notebookCacheRaw;
  }

  // Get cache content
  public getCacheContent(): Observable<CacheContent | any> {
    const projectId = this.#appState.projectId();
    const versionId = this.#appState.versionId();
    const notebookId = this.#appState.notebookId();
    const cacheId = this.#appState.cacheId() || LocalstorageHelper.getCacheId();

    if (!projectId || !versionId || !notebookId || !cacheId) {
      return EMPTY;
    }

    return this.http.get<CacheContent>(
      `${API_BASE}${PROJECT}/${projectId}/${VERSION}/${versionId}/${BOOK}/${notebookId}/${CACHE}/${cacheId}/${FILES}`
    );
  }

  // Create cache content
  public createCacheContent(data: any): Observable<object> {
    const projectId = this.#appState.projectId();
    const versionId = this.#appState.versionId();
    const notebookId = this.#appState.notebookId();
    const cacheId = this.#appState.cacheId() || LocalstorageHelper.getCacheId();

    if (!projectId || !versionId || !notebookId || !cacheId) {
      return EMPTY;
    }

    return this.http.post<CacheContent>(
      `${API_BASE}${PROJECT}/${projectId}/${VERSION}/${versionId}/${BOOK}/${notebookId}/${CACHE}/${cacheId}/${FILES}`,
      data
    );
  }

  // Get specific cache content file
  public getCacheContentFile(
    fileId: string | any,
    responseType: 'text' | 'blob' | 'arraybuffer' | 'document' | 'json' = 'text'
  ): Observable<CacheContent | any> {
    return this.getFileFromIndexDBOrAPI(fileId, responseType).pipe(
      switchMap((fileFromIndexDB) => {
        if (fileFromIndexDB) {
          return of(fileFromIndexDB);
        } else {
          // Rest of the original logic for getting the file
          const projectId = this.#appState.projectId();
          const versionId = this.#appState.versionId();
          const notebookId = this.#appState.notebookId();
          const cacheId =
            this.#appState.cacheId() || LocalstorageHelper.getCacheId();
          if (!projectId || !versionId || !notebookId || !cacheId) {
            return EMPTY;
          }

          return this.http.get<CacheContent>(
            `${API_BASE}${PROJECT}/${projectId}/${VERSION}/${versionId}/${BOOK}/${notebookId}/${CACHE}/${cacheId}/${FILES}/${fileId}`,
            { responseType } as any
          );
        }
      }),
      catchError((error) => throwError(() => error))
    );
  }

  // Update specific cache content file
  public updateCacheContentFile(
    fileId: string | any,
    data: CacheContent | any
  ): Observable<object> {
    const projectId = this.#appState.projectId();
    const versionId = this.#appState.versionId();
    const notebookId = this.#appState.notebookId();
    const cacheId = this.#appState.cacheId() || LocalstorageHelper.getCacheId();

    if (!projectId || !versionId || !notebookId || !cacheId || !fileId) {
      return EMPTY;
    }

    return this.http.put<CacheContent>(
      `${API_BASE}${PROJECT}/${projectId}/${VERSION}/${versionId}/${BOOK}/${notebookId}/${CACHE}/${cacheId}/${FILES}/${fileId}`,
      data
    );
  }

  // Delete specific cache content file
  public deleteCacheContentFile(fileId: string): Observable<object> {
    const projectId = this.#appState.projectId();
    const versionId = this.#appState.versionId();
    const notebookId = this.#appState.notebookId();
    const cacheId = this.#appState.cacheId() || LocalstorageHelper.getCacheId();

    if (!projectId || !versionId || !notebookId || !cacheId) {
      return EMPTY;
    }

    return this.http
      .delete(
        `${API_BASE}${PROJECT}/${projectId}/${VERSION}/${versionId}/${BOOK}/${notebookId}/${CACHE}/${cacheId}/${FILES}/${fileId}`
      )
      .pipe(
        map(async (res) => {
          await this.fileDataService.deleteFileFromIndexDB(
            fileId,
            NOTEBOOK_FILE
          );
          return res;
        })
      );
  }

  private getFileFromIndexDBOrAPI(
    fileId: string | any,
    responseType: string
  ): Observable<any> {
    return from(
      this.fileDataService.checkKeyExists(fileId, NOTEBOOK_FILE)
    ).pipe(
      switchMap((isInIndexDB: boolean) => {
        if (isInIndexDB) {
          return from(
            this.fileDataService.getFileFromIndexDB(fileId, NOTEBOOK_FILE)
          );
        } else {
          const projectId = this.#appState.projectId();
          const versionId = this.#appState.versionId();
          const notebookId = this.#appState.notebookId();
          const cacheId =
            this.#appState.cacheId() || LocalstorageHelper.getCacheId();

          if (!projectId || !versionId || !notebookId || !cacheId || !fileId) {
            return of(null);
          }

          return this.http.get<CacheContent>(
            `${API_BASE}${PROJECT}/${projectId}/${VERSION}/${versionId}/${BOOK}/${notebookId}/${CACHE}/${cacheId}/${FILES}/${fileId}`,
            { responseType } as any
          );
        }
      }),
      catchError((error) => throwError(() => error))
    );
  }

  // ─────────────────────────────────────────────────────────────────────
  // This method is used in quick.js and pyodide
  // ─────────────────────────────────────────────────────────────────────
  public saveNotebookFile(data: any[], chunkCotext: ExecutionContext) {
    // Validate that data is an array of exactly two elements
    if (!Array.isArray(data) || data.length !== 2) {
      chunkCotext.addMessage(ERROR_SAVE_PROJECT_FILE, 'danger');
      return; // Stop execution if data is invalid
    }

    // Destructure data into fileNameWithExtension and fileContent
    const [fileNameWithExtension, fileContent] = data;

    // Further validation can be performed here, such as checking the types of fileNameWithExtension and fileContent
    if (
      typeof fileNameWithExtension !== 'string' ||
      !fileNameWithExtension.includes('.')
    ) {
      chunkCotext.addMessage(ERROR_SAVE_PROJECT_FILE, 'danger');
      return; // Stop execution if fileNameWithExtension is not a string or lacks an extension
    }

    // Extract fileName and fileExtension using provided helper methods
    const fileName = GeneralHelpers.fileNameFromString(fileNameWithExtension);
    const fileExtension = GeneralHelpers.fileExtensionFromString(
      fileNameWithExtension
    );

    this.getCacheContent().subscribe({
      next: (res: NotebookFile[] | any) => {
        const fileList = res;
        const presentedFileIndex = GeneralHelpers.findIndexInArrayByProp(
          fileList,
          'name',
          `${fileName}.${fileExtension}`
        );

        if (presentedFileIndex > -1) {
          const notebookFile: NotebookFile | any = fileList.find(
            (item: NotebookFile) => item.name === fileNameWithExtension
          );

          if (notebookFile) {
            const formData = this.fileDataService.prepareFileData(
              fileName,
              fileExtension,
              fileContent
            );
            this.updateCacheContentFile(
              notebookFile.fileId,
              formData
            ).subscribe();
          }
        } else {
          this.createFileAndSave(fileContent, fileExtension, fileName);
        }
      },
      error: (error: any) => {
        console.error('Error getting cache content', error);
      },
    });
  }

  private createFileAndSave(
    fileContent: any,
    fileExtension: string,
    fileName: string
  ) {
    const formData = this.fileDataService.prepareFileData(
      fileName,
      fileExtension,
      fileContent
    );
    this.createCacheContent(formData).subscribe({
      next: () => {
        this.toastrService.success(`Item ${fileName} exported`);

        this.messageService.sendMessage(RELOAD_NOTEBOOK_FILES);
      },
      error: () => { },
    });
  }
}
