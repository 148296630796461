import {
  CACHE_ID,
  FILE_MODULE_LIST,
  NOTEBOOK_LIST,
  PROJECT_LIST,
  CURRENT_EDITOR,
  RIGHT_PANEL_VISIBLE,
  LEFT_PANEL_VISIBLE,
  RECENT_PROJECTS,
  PROJECT_LOCKED,
} from '../constants/general.constants';
import { Project } from '../interfaces/project.interface';
import { GeneralHelpers } from './general.helper';

export class LocalstorageHelper {
  // Setter methods

  static setFileModuleList(fileModuleList: string): void {
    localStorage.setItem(FILE_MODULE_LIST, fileModuleList);
  }

  // Getter methods

  static getProjectList(): string | null {
    return localStorage.getItem(PROJECT_LIST);
  }

  static getNotebookList(): string | null {
    return localStorage.getItem(NOTEBOOK_LIST);
  }

  static getFileModuleList(): string | null {
    return localStorage.getItem(FILE_MODULE_LIST);
  }

  static getCurrentEditor() {
    return localStorage.getItem(CURRENT_EDITOR);
  }

  static clear(): void {
    localStorage.clear();
  }

  static getProjectListObject(): Project[] | any {
    let projectList = LocalstorageHelper.getProjectList();

    if (projectList !== null) {
      projectList = GeneralHelpers.jsonParse(projectList);

      return projectList;
    }

    return [];
  }

  static setTouchedChunkId(chunkId: string) {
    localStorage.setItem('touchedChunkId', chunkId);
  }

  static getTouchedChunkId() {
    return localStorage.getItem('touchedChunkId');
  }

  static removeTouchedChunkId() {
    localStorage.removeItem('touchedChunkId');
  }

  static getNotebookCache() {
    return localStorage.getItem('notebookCache');
  }

  static setNotebookCache(notebookCache: string) {
    localStorage.setItem('notebookCache', notebookCache);
  }

  static removeNotebookCache() {
    localStorage.removeItem('notebookCache');
  }

  static setCahceId(cacheId: number) {
    localStorage.setItem(CACHE_ID, `${cacheId}`);
  }

  static getCacheId() {
    const cacheId = localStorage.getItem(CACHE_ID);
    return cacheId ? parseInt(cacheId, 10) : null;
  }

  static removeCacheId() {
    localStorage.removeItem(CACHE_ID);
  }

  static setLeftPanelVisible(visible: boolean): void {
    localStorage.setItem(LEFT_PANEL_VISIBLE, JSON.stringify(visible));
  }

  static getLeftPanelVisible(): boolean {
    const value = localStorage.getItem(LEFT_PANEL_VISIBLE);
    return value === null ? true : JSON.parse(value);
  }

  static setRightPanelVisible(visible: boolean): void {
    localStorage.setItem(RIGHT_PANEL_VISIBLE, JSON.stringify(visible));
  }

  static getRightPanelVisible(): boolean {
    const value = localStorage.getItem(RIGHT_PANEL_VISIBLE);
    return value === null ? true : JSON.parse(value);
  }

  static setRecentProjects(recentProjects: Project[]): void {
    localStorage.setItem(RECENT_PROJECTS, JSON.stringify(recentProjects));
  }

  static getRecentProjects(): Project[] {
    const recentProjectsString = localStorage.getItem(RECENT_PROJECTS);
    return recentProjectsString ? JSON.parse(recentProjectsString) : [];
  }

  static addRecentProject(project: Project): void {
    const recentProjects = this.getRecentProjects();
    const updatedProjects = [
      project,
      ...recentProjects.filter((p) => p.projectId !== project.projectId),
    ].slice(0, 3);
    this.setRecentProjects(updatedProjects);
  }

  static setProjectLockFlag(isLocked: boolean): void {
    localStorage.setItem(PROJECT_LOCKED, isLocked.toString());
  }

  static getProjectLockFlag(): boolean {
    const flag = localStorage.getItem(PROJECT_LOCKED);
    return flag ? flag === 'true' : false;
  }

  static removeProjectLockFlag(): void {
    localStorage.removeItem(PROJECT_LOCKED);
  }
}
