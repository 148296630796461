import { Component, OnDestroy, OnInit, input, signal } from '@angular/core';
import { ArrowClient } from '../../../services/arrow-websocket/arrow-client'
import {
  DataSet, DataSetView,
} from '../../../interfaces/arrow-alias.interface';
import { ArrowSchema } from '../arrow-schema.interface';
import { ExecutionContext } from '../../../interfaces/chunk/chunk-context.interface'
import { AutoUnsubscribe } from '../../../decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'dataset-viewer',
  templateUrl: './dataset-viewer.component.html',
  styleUrl: './dataset-viewer.component.scss'
})
export class DatasetViewerComponent implements OnInit, OnDestroy {
  arrowClient = input.required<ArrowClient>();
  context = input.required<ExecutionContext>();
  protected dataSets: DataSet[] = [];
  selectedOption = signal<number>(0);
  selectedDataSet: DataSet | undefined;
  currentSchema: ArrowSchema | undefined;
  currentViews = signal<DataSetView[]>([]);

  ngOnInit(): void {
    this.dataSets = this.arrowClient().listDataSets();
    if (this.dataSets.length > 0) {
      this.selectedOption.set(1);
      this.selectDataSetAtIndex(1);
    }
  }

 // eslint-disable-next-line
 ngOnDestroy() {
    // intentionally empty
  }

  selectDataSetAtIndex(index: number): void {
    this.selectedDataSet = this.dataSets[index - 1];
    this.arrowClient().getSchema(this.selectedDataSet.name).then((schema) => {
      this.currentSchema = new ArrowSchema(schema);
    });
    this.arrowClient().listViews(this.selectedDataSet.name).then((views) => {
      this.currentViews.set(views);
    });
  }

  selectDataSet(event: Event): void {
    if (!(event.target instanceof HTMLSelectElement)) {
      return;
    }
    if (event.target.selectedIndex === 0) {
      this.selectedDataSet = undefined;
    } else {
      this.selectDataSetAtIndex(event.target.selectedIndex);
    }
  }

  deleteSelectedDataSet(): void {
    const client = this.arrowClient();
    const dataset = this.selectedDataSet;
    if (!client || !dataset) {
      return;
    }
    if (!confirm(`Are you sure you want to delete dataset '${dataset.name}'?`)) {
      return;
    }
    this.context().setBusy(true, 'Deleting dataset');
    client.deleteDataSet(dataset.name).then(() => {
      this.context().addMessage(`Dataset '${dataset.name}' deleted`, 'info');
      this.loadDataSets();
    }).finally(() => {
      this.context().setBusy(false, 'idle');
    });
  }

  deleteView(view: DataSetView): void {
    const client = this.arrowClient();
    const dataset = this.selectedDataSet;
    if (!client || !dataset) {
      return;
    }
    this.context().setBusy(true, 'Deleting view');
    client.deleteView(dataset.name, view.name).then(() => {
      this.context().addMessage(`View '${view.name}' deleted`, 'info');
      this.currentViews.update((views) => views.filter((v) => v.view_id !== view.view_id));
      this.loadDataSets();
    }).finally(() => {
      this.context().setBusy(false, 'idle');
    });

  }

  loadDataSets() {
    if (this.arrowClient()) {
      this.context().setBusy(true, 'Refreshing data sets');
      this.arrowClient().refreshDataSetList().then((dataSets) => {
        this.dataSets = dataSets as DataSet[];
      }).catch((error) => {
        this.context().addMessage(error.message, 'danger');
      }).finally(() => {
        this.context().setBusy(false, 'idle');
      });
    }
  }
}
