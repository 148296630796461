<div class="row">
  <div class="col m-2" style="max-width: 300px">
    <div class="row">
      <div class="col">
        <button
          mat-icon-button
          matTooltip="Add Connection"
          (click)="doAddDB()"
          tabindex="-1"
        >
          <mat-icon>add_circle_outline</mat-icon>
        </button>
        <button
          mat-icon-button
          matTooltip="Duplicate"
          [disabled]="selectedDB() === undefined"
          (click)="doDuplicateDB()"
          tabindex="-1"
        >
          <mat-icon>content_copy</mat-icon>
        </button>
        <button
          mat-icon-button
          matTooltip="Delete"
          [disabled]="selectedDB() === undefined"
          (click)="doDeleteDB()"
          tabindex="-1"
        >
          <mat-icon>delete_outline</mat-icon>
        </button>
      </div>
    </div>
    <table-list [dataSource]="aliases$" [columns]="aliasListColumns" [options]="{showHeader: false}" (selectionChanged)="aliasSelected($event)"></table-list>
  </div>

  <div class="col m-2">
    <form [formGroup]="dbForm">
      <div class="row">
        <div class="col form-floating mb-2">
          <mat-form-field appearance="outline">
            <mat-label>Name</mat-label>
            <input
              matInput
              id="nameField"
              placeholder="db connection"
              formControlName="name"
            />
          </mat-form-field>
        </div>

        <div class="col form-floating mb-2">
          <mat-form-field appearance="outline">
            <mat-label>Alias ID</mat-label>
            <input
              matInput
              readonly
              id="dbAliasId"
              placeholder="1000"
              formControlName="dbAliasId"
              tabindex="-1"
            />
          </mat-form-field>
        </div>
      </div>
      <div>
        <div class="row">
          <div class="col form-floating mb-2">
            <mat-form-field appearance="outline">
              <mat-label>Host</mat-label>
              <input
                matInput
                id="hostField"
                placeholder="host"
                formControlName="host"
              />
            </mat-form-field>
          </div>

          <div class="col form-floating mb-2">
            <mat-form-field appearance="outline">
              <mat-label>Port</mat-label>
              <input
                matInput
                id="portField"
                placeholder="5432"
                formControlName="port"
                pattern="[0-9]+"
              />
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col form-floating mb-2">
            <mat-form-field appearance="outline">
              <mat-label>User</mat-label>
              <input
                matInput
                id="userField"
                placeholder="dbadmin"
                formControlName="username"
              />
            </mat-form-field>
          </div>

          <div class="col form-floating mb-2">
            <mat-form-field appearance="outline">
              <mat-label>Database Name</mat-label>
              <input
                matInput
                id="dbnameField"
                placeholder="postgres"
                formControlName="dbname"
              />
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row align-items-center justify-content-between">
        <div class="col form-floating mb-2">
          <mat-form-field appearance="outline">
            <mat-label>DB Secret</mat-label>
            <mat-select formControlName="passwordSecretId" [value]="selectedSecret?.secretId" (selectionChange)="onSecretSelected($event)">
              <mat-option *ngFor="let secret of availableSecrets" [value]="secret.secretId">
                {{ secret.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div class="col mb-2 text-end">
          <button
            mat-stroked-button
            color="secondary"
            [disabled]="disableButtons()"
            (click)="doResetForm()"
          >
            Reset
          </button>
          <button
            mat-stroked-button
            color="primary"
            (click)="doSaveDB()"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  </div>
</div>
<div class="row" *ngIf="errorMessage().length > 0">
  <div class="col">
    <div class="alert alert-danger" role="alert">
      {{ errorMessage() }}
    </div>
  </div>
</div>
