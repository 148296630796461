type RawField = {
  name: string;
  data_type: string | { List: { data_type: string } } | { Timestamp: [] };
  nullable: boolean;
  metadata: Record<string, string>;
};

export class ArrowField {
  index: number;
  name: string;
  type: string = 'Unsupported';
  nullable: boolean;
  metadata: Record<string, string>;

  constructor(index: number, rawObject: object) {
    this.index = index;
    const obj = rawObject as RawField;
    this.name = obj.name;
    if (typeof obj.data_type == 'string') {
      this.type = obj.data_type;
    } else if (typeof obj.data_type == 'object') {
      if (Object.prototype.hasOwnProperty.call(obj.data_type, 'List')) {
        const subtype = Object(obj.data_type)["List"] as RawField;
        this.type = `List (${subtype.data_type})`;
      } else if (Object.prototype.hasOwnProperty.call(obj.data_type, 'Timestamp')) {
        this.type = 'Timestamp';
      } else {
        this.type = 'Unsupported';
      }
    }
    this.nullable = obj.nullable;
    this.metadata = obj.metadata;
  }
}

export class ArrowSchema {
  fields: ArrowField[];

  constructor(schema: object) {
    const obj = schema as ArrowSchema;
    this.fields = obj.fields.map((field: object, index: number) => new ArrowField(index, field));
  }
}

export type RawArrowTable = {
  table_id: number,
  config_id: string,
  name: string,
  bucket_path: string,
  bucket_name: string,
  file_etag: string,
  src_path: string,
  src_bucket: string,
  src_etag: string,
}
