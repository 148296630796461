import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatSnackBarModule } from '@angular/material/snack-bar';
// Your dialog imports
import { CreateDialogModule } from './modules/create-dialog/create-dialog.module';
import { ConfirmDialogModule } from './modules/confirm-dialog/confirm-dialog.module';
import { GlobalVarDialogModule } from './modules/global-var-dialog/global-var-dialog.module';
import { UrlDialogModule } from './modules/url-dialog/url-dialog.module';
import { WatchFileDialogModule } from './modules/watch-file-dialog/watch-file-dialog.module';
import { ChunkCopyToDialogModule } from './modules/chunk-copy-to-dialog/chunk-copy-to-dialog.module';
import { DuckDbControldDialogModule } from './modules/duck-db-controls-dialog/duck-db-controls-dialog.module';
import { ArrowAliasModule } from './modules/arrow-alias/arrow-alias.module';
import { AddProjectDialogModule } from './modules/add-project-dialog/add-project-dialog.module';
import { EditProjectDialogModule } from './modules/edit-project-dialog/edit-project-dialog.module';
import { ManageTeamDialogModule } from './modules/manage-team-dialog/manage-team-dialog.module';
import { VersionDialogModule } from './modules/version-dialog/version-dialog.module';
import { ChunkDialogModule } from './modules/chunk-dialog/chunk-dialog.module';
import { ImportProjectDialogModule } from './modules/import-project-dialog/import-project-dialog.module';
import { InputDialogModule } from './modules/input-dialog/input-dialog.module';
import { PipesModule } from './pipes/pipes.module';
import { ArrowExplorerModule } from './modules/arrow-explorer/arrow-explorer.module';
import { ChunkItemInfoModule } from './modules/chunk-item-description/chunk-item-description.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatSnackBarModule,
    CreateDialogModule,
    ConfirmDialogModule,
    GlobalVarDialogModule,
    UrlDialogModule,
    WatchFileDialogModule,
    ChunkCopyToDialogModule,
    DuckDbControldDialogModule,
    ArrowAliasModule,
    AddProjectDialogModule,
    EditProjectDialogModule,
    ManageTeamDialogModule,
    VersionDialogModule,
    ChunkDialogModule,
    ImportProjectDialogModule,
    PipesModule,
    ArrowExplorerModule,
    ChunkItemInfoModule,
    InputDialogModule,
  ],
})
export class SharedModule { }
