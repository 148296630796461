import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Chunk } from '../../interfaces/chunk/chunk.interface';
import { Dialog } from '../../interfaces/create-dialog.interface'
import { Notebook } from '../../interfaces/project.interface';
import { ChunkService } from '../../services/chunk/chunk.service';
import { AutoUnsubscribe } from '../../decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-chunk-copy-to-dialog',
  templateUrl: './chunk-copy-to-dialog.component.html',
  styleUrls: ['./chunk-copy-to-dialog.component.scss']
})
export class ChunkCopyToDialogComponent implements OnInit, OnDestroy {
  public chunks!: Chunk[];
  public notebooks!: Notebook[];
  public selectedNotebook!: Notebook | unknown;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Dialog,
    private chunkService: ChunkService,
  ) { }

  ngOnInit() {
    this.chunks = this.data.dialogData.chunks;
    this.notebooks = this.data.dialogData.notebooks;
  }

 // eslint-disable-next-line
 ngOnDestroy() {
    // intentionally empty
  }

  public getChunkName(chunk: Chunk) {
    return chunk.name || `${chunk.chunkId} chunk`;
  }

  public selectNotebook(notebook: any) {
    this.selectedNotebook = notebook.value
  }
}
