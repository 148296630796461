import { Injectable } from '@angular/core';
import { PythonHelper } from './python.helper';


@Injectable({
  providedIn: 'root',
})
export class PythonRuntimeService {
  private pythonRuntime: any;
  private jsExternalModulesNamespaces: any;
  private isInitialized: boolean = false;

  constructor() { }

  async initialize(runtime: any, jsExternalModulesNamespaces: any) {
    this.pythonRuntime = runtime;
    this.jsExternalModulesNamespaces = jsExternalModulesNamespaces;
    // We'll now use the module system's initialization
    this.isInitialized = true;
  }

  async executeCode(code: string, namespaceObj?: any, contextId?: string): Promise<any> {
    if (!this.isInitialized) {
      throw new Error('Runtime not initialized');
    }

    try {
      const executionCode = `await module_system.execute_code(
        ${JSON.stringify(code)},
        ${namespaceObj ? JSON.stringify(namespaceObj) : 'None'},
        ${contextId ? JSON.stringify(contextId) : 'None'}
      )`;
      const result = await this.pythonRuntime.runPythonAsync(executionCode);
      let {
        output = '',
        error = '',
        // eslint-disable-next-line
        namespace = {},
        // eslint-disable-next-line
        exports = []
      } = PythonHelper.pythonToJs(result, this.pythonRuntime);
      output = output.trim();
      error = error.trim();

      return {
        output: output || error,
        namespace,
        exports,
        error: error || null
      };

    } catch (error) {
      console.error('Error executing code:', error);
      throw error;
    }
  }

  getPythonRuntime() {
    return this.pythonRuntime;
  }
}
