import { Component, effect, input, OnDestroy, output, signal } from '@angular/core';
import { DataSet, DataSetView } from '../../../interfaces/arrow-alias.interface';
import { TableListColDef } from '../../table-list/table-list.component'
import { toObservable } from '@angular/core/rxjs-interop'
import { AutoUnsubscribe } from '../../../decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'arrow-views-list',
  templateUrl: './views-list.component.html',
  styleUrl: './views-list.component.scss'
})
export class ViewsListComponent implements OnDestroy {
  views = input.required<DataSetView[]>();
  dataset = input.required<DataSet>();
  deleteView = output<DataSetView>();

  views$ = toObservable(this.views);
  selectedView = signal<DataSetView | undefined>(undefined);

  protected viewsColumns: TableListColDef<DataSetView>[] = [
    { header: 'ID', field: 'view_id' },
    { header: 'Name', field: 'name' },
    { header: 'Expires', field: 'expires_at' },
  ];

  constructor() {
    effect(() => {
      if (this.selectedView()) {
        console.log("view selected");
      }
    });
  }

 // eslint-disable-next-line
 ngOnDestroy() {
    // This is intentional
  }

  onDeleteView() {
    const view = this.selectedView();
    if (!view) {
      return;
    }
    if (confirm(`Are you sure you want to delete the view '${view.name}'?`)) {
      this.deleteView.emit(view);
    }
  }

  viewSelected(view: DataSetView | undefined) {
    console.log("view selected");
    this.selectedView.set(view);
  }
}
