import { Component, Inject, OnDestroy, OnInit, signal } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ArrowAliasV2 } from '../../../interfaces/arrow-alias.interface';
import { Dialog } from '../../../interfaces/create-dialog.interface';
import { ArrowWebsocketService } from '../../../services/arrow-websocket/arrow-websocket.service'
import { ArrowClient } from '../../../services/arrow-websocket/arrow-client'
import { ExecutionContext } from 'src/app/shared/interfaces/chunk/chunk-context.interface';
import { DisplayMessageType } from 'src/app/shared/interfaces/display-message.interface';
import { AutoUnsubscribe } from 'src/app/shared/decorators/auto-unsubscribe.decorator';

type MyAlertType = { kind: DisplayMessageType; message: string; };

@AutoUnsubscribe()
@Component({
  selector: 'app-arrow-explorer',
  templateUrl: './arrow-explorer.component.html',
  styleUrl: './arrow-explorer.component.scss',
})
export class ArrowExplorerComponent implements OnInit, ExecutionContext, OnDestroy {
  constructor(
    public dialogRef: MatDialogRef<ArrowExplorerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Dialog,
    private arrrowService: ArrowWebsocketService,
  ) { }

  protected arrowClient = signal<ArrowClient | undefined>(undefined);
  alerts: MyAlertType[] = [];
  protected busySignal = signal<boolean>(false);
  protected busyMessageSignal = signal<string>('idle');

  ngOnInit(): void {
    const alias = this.data.dialogData.alias as ArrowAliasV2;
    if (!alias) {
      throw new Error('ArrowExplorerComponent: alias is required');
    }
    this.setBusy(true, 'Connecting to Arrow');
    this.arrrowService.connectToAliasNamed(alias.name, this).then((client) => {
      this.arrowClient.set(client);
      this.setBusy(false, 'idle');
    }).catch((error) => {
      this.addMessage(`Failed to connect to Arrow: ${error}`, 'danger');
      this.setBusy(false, 'idle');
    });
  }

 // eslint-disable-next-line
 ngOnDestroy() {
    // intentionally empty
  }

  onAlertClosed(dismissedAlert: MyAlertType): void {
    this.alerts = this.alerts.filter((alert) => alert !== dismissedAlert);
  }

  close(): void {
    this.dialogRef.close();
  }

  setBusy(busy: boolean, status: string): void {
    this.busySignal.set(busy);
    this.busyMessageSignal.set(status);
  }

  addLog(log: string): void {
    console.log(log);
  }

  addMessage(message: string, type: DisplayMessageType): void {
    this.alerts.push({ kind: type, message });
  }

  clearLogs(): void {
    // nothing to do
  }

  clearMessages(): void {
    this.alerts = [];
  }

  removeMessage(id: number): void {
    this.alerts.splice(id, 1);
  }

  showImageGroup(): void {
    this.addMessage('showImageGroup not supported', 'info');
  }

  showImages(): void {
    this.addMessage('showImages not supported', 'info');
  }

  addOutput(output: string): void {
    console.log(output);
  }

  clearOutput() {
    // nothing to do
  }
}
