import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { User } from '../../interfaces/user.interface';
import { environment } from '../../../../environments/environment';

const authConfig: AuthConfig = {
  issuer: environment.auth.zitadel.issuer,
  redirectUri: environment.auth.zitadel.redirectUri,
  clientId: environment.auth.zitadel.clientId,
  responseType: 'code',
  scope: 'openid profile email offline_access',
  showDebugInformation: environment.auth.zitadel.showDebugInformation,
  requireHttps: environment.auth.zitadel.requireHttps,
  strictDiscoveryDocumentValidation: true,
  requestAccessToken: true,
  skipIssuerCheck: false,
  clearHashAfterLogin: true,
  timeoutFactor: 0.75,
  sessionChecksEnabled: true,
  silentRefreshTimeout: 5000,
  silentRefreshShowIFrame: false, // Note the capital 'F' here
  useSilentRefresh: true,
  postLogoutRedirectUri: environment.auth.zitadel.postLogoutRedirectUri,
  logoutUrl: environment.auth.zitadel.postLogoutRedirectUri,
  silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
  sessionCheckIntervall: 30 * 1000,
  disableAtHashCheck: false,
  jwks: {
    keys: [],
  },
  customQueryParams: {
    prompt: 'consent',
  },
};

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  private userSubject = new BehaviorSubject<User | null>(null);
  user$: Observable<User | null> = this.userSubject.asObservable();

  private loggedIn = new BehaviorSubject<boolean>(false);
  isLoggedIn$ = this.loggedIn.asObservable();

  private oauthService = inject(OAuthService);
  private router = inject(Router);

  constructor() { }

  async initializeAuth(): Promise<void> {
    try {
      // Configure the OAuth service
      this.oauthService.configure(authConfig);

      // Load discovery document
      await this.oauthService.loadDiscoveryDocument();

      // Setup automatic refresh
      this.oauthService.setupAutomaticSilentRefresh();

      // Check if we have an authorization code in the URL
      const hasCode = window.location.href.includes('code=');

      if (hasCode) {
        // If we have a code, process it
        await this.oauthService.tryLoginCodeFlow();
      } else {
        // If no code, try silent refresh if we have tokens
        if (this.oauthService.hasValidAccessToken()) {
          try {
            await this.oauthService.initCodeFlow();
          } catch (error) {
            console.error('Silent refresh failed:', error);
          }
        }
      }

      // Subscribe to token events
      this.oauthService.events.subscribe(event => {
        switch (event.type) {
          case 'token_received':
            this.handleSuccessfulLogin();
            break;
          case 'token_expires':
            this.handleTokenExpiration();
            break;
          case 'token_refresh_error':
            this.handleRefreshError();
            break;
          case 'session_terminated':
          case 'session_error':
            this.handleAuthError();
            break;
        }
      });

      // Check initial authentication state
      if (this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken()) {
        await this.handleSuccessfulLogin();
      }
    } catch (error) {
      console.error('Error in auth initialization:', error);
      this.handleAuthError();
    }
  }

  private async handleSuccessfulLogin(): Promise<void> {
    try {
      const claims = this.oauthService.getIdentityClaims();
      if (claims) {
        const user: User = {
          sub: claims['sub'],
          name: claims['name'] || claims['preferred_username'],
          email: claims['email'],
          picture: claims['picture'],
          given_name: claims['given_name'],
          family_name: claims['family_name'],
        };
        this.userSubject.next(user);
        this.loggedIn.next(true);

        // Handle saved return URL if exists
        const returnUrl = this.oauthService.state;
        if (returnUrl && returnUrl !== '/') {
          this.oauthService.state = '';
          await this.router.navigateByUrl(returnUrl);
        }
      }
    } catch (error) {
      console.error('Error handling successful login:', error);
      this.handleAuthError();
    }
  }

  async authenticate(returnUrl?: string): Promise<void> {
    if (!this.oauthService.hasValidIdToken()) {
      if (returnUrl) {
        this.oauthService.state = returnUrl;
      }
      this.oauthService.initLoginFlow();
      return;
    }

    await this.handleSuccessfulLogin();
  }

  async isAuthenticated(): Promise<boolean> {
    return this.oauthService.hasValidIdToken() && this.oauthService.hasValidAccessToken();
  }

  async getIdToken(): Promise<string | null> {
    try {
      if (this.oauthService.hasValidIdToken()) {
        return this.oauthService.getIdToken();
      }
      return null;
    } catch (error) {
      console.error('Error getting ID token:', error);
      return null;
    }
  }

  async logout(): Promise<void> {
    try {
      // Clear local state
      this.userSubject.next(null);
      this.loggedIn.next(false);

      // this.oauthService.revocationEndpoint =
      //   `${environment.auth.zitadel.issuer}/auth/v1/users/me/tokens/refresh/${this.oauthService.getIdToken()}`;

      // Perform OAuth logout
      // await this.oauthService.revokeTokenAndLogout({
      //   client_id: environment.auth.zitadel.clientId,
      //   post_logout_redirect_uri: environment.auth.zitadel.postLogoutRedirectUri,
      // });

      await this.oauthService.logOut({
        client_id: environment.auth.zitadel.clientId,
        post_logout_redirect_uri: environment.auth.zitadel.postLogoutRedirectUri,
      });

      await this.router.navigate(['/not-authenticated']);
    } catch (error) {
      console.error('Error during logout:', error);
      this.handleAuthError();
    }
  }

  private handleTokenExpiration(): void {
    this.oauthService.silentRefresh().catch(error => {
      console.error('Error refreshing token:', error);
      this.handleAuthError();
    });
  }

  private handleRefreshError(): void {
    console.error('Failed to refresh token');
    this.handleAuthError();
  }

  private handleAuthError(): void {
    this.userSubject.next(null);
    this.loggedIn.next(false);
    this.router.navigate(['/not-authenticated']);
  }

  getZitadelUser(): Observable<any> {
    if (!this.oauthService.hasValidAccessToken()) {
      return of(null);
    }

    const claims = this.oauthService.getIdentityClaims();
    if (!claims) {
      return of(null);
    }

    return of(claims);
  }

  // Helper method for debugging
  debugTokens(): void {
    if (!environment.auth.zitadel.showDebugInformation) {
      return;
    }

    console.group('Auth Debug Information');
    console.log('Access Token:', this.oauthService.getAccessToken());
    console.log('ID Token:', this.oauthService.getIdToken());
    console.log('Refresh Token:', this.oauthService.getRefreshToken());
    console.log('Access Token Expiration:', new Date(this.oauthService.getAccessTokenExpiration()));
    console.log('Id Token Expiration:', new Date(this.oauthService.getIdTokenExpiration()));
    console.log('Token Valid?', this.oauthService.hasValidAccessToken());
    console.log('ID Token Valid?', this.oauthService.hasValidIdToken());
    console.log('User Claims:', this.oauthService.getIdentityClaims());
    console.groupEnd();
  }
}
