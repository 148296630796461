import { Injectable } from '@angular/core';

import Module from '../../../../../assets/local_wasm/search-wasm/search-wasm';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class WasmViewService {
  // C API functions
  private toggleWrapped: any;

  constructor(private toastrService: ToastrService) {
    this.initStoireMethods();
  }

  private initStoireMethods() {
    // Store C API functions
    this.toggleWrapped = Module.cwrap("Toggle", "number", []);
  }

  // ─────────────────────────────────────────────────────────────────────────────
  // Public methods
  // ─────────────────────────────────────────────────────────────────────────────

  public toggle() {
    try {
      this.toggleWrapped();
    } catch (error) {
      console.error("Error in toggle: ", error);
      this.toastrService.error("Error in toggle: " + error);
    }
    return 
  }
}
