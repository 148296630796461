import { Component, OnDestroy, ViewEncapsulation } from '@angular/core';
import { LoadingService } from '../../shared/services/loading/loading.service';
import { AutoUnsubscribe } from 'src/app/shared/decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
})
export class SpinnerComponent implements OnDestroy {
  constructor(public loadingService: LoadingService) { }

 // eslint-disable-next-line
 ngOnDestroy() {
    // This is intentional
  }
}
