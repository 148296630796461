import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  private toastr = inject(ToastrService);
  private router = inject(Router);
  private oauthService = inject(OAuthService);

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        // Skip error handling for OAuth-related requests
        if (error.error instanceof ArrayBuffer) {
          const uint8Array = new Uint8Array(error.error);
          return throwError(() => ({
            ...error,
            error: { message: String.fromCharCode(...uint8Array).replace(/"/g, '') }
          }));
        }

        if (this.shouldSkipErrorHandling(request)) {
          return throwError(() => error);
        }

        return this.errorHandler(error);
      })
    );
  }

  private shouldSkipErrorHandling(request: HttpRequest<any>): boolean {
    const issuerUrl = environment.auth.zitadel.issuer;

    // Skip OAuth/OIDC related endpoints
    if (request.url.startsWith(issuerUrl)) {
      return true;
    }

    // Skip well-known endpoints
    if (request.url.includes('.well-known/openid-configuration')) {
      return true;
    }

    // Skip token endpoints
    if (
      request.url.includes('/token') ||
      request.url.includes('/oauth/token') ||
      request.url.includes('/oauth2/token')
    ) {
      return true;
    }

    // Skip userinfo endpoint
    if (request.url.includes('/userinfo')) {
      return true;
    }

    return false;
  }

  private errorHandler(error: HttpErrorResponse): Observable<never> {
    this.renderErrors(error);
    return throwError(() => error);
  }

  private renderErrors(error: HttpErrorResponse): void {
    const errorMessages: string[] = this.parseErrorMessages(error);

    switch (error.status) {
      case 401:
        this.handleUnauthorized();
        return;
      case 403:
        this.handle403Error();
        return;
      case 404:
        this.router.navigate(['/404']);
        return;
      case 500:
      case 503:
        this.router.navigate(['/500']);
        return;
    }

    if (Array.isArray(errorMessages) && errorMessages.length > 0) {
      for (const message of errorMessages) {
        this.showError(`${error.status}: ${message}`);
      }
    }
  }

  private handleUnauthorized(): void {
    // Store current URL for redirect after login
    // const currentUrl = window.location.pathname + window.location.search;
    // sessionStorage.setItem('returnUrl', currentUrl);

    // Initiate login flow directly with OAuthService
    this.oauthService.initLoginFlow();
  }

  private handle403Error(): void {
    if (this.oauthService.hasValidIdToken()) {
      this.router.navigate(['/access-denied']);
    } else {
      this.handleUnauthorized();
    }
  }

  private parseErrorMessages(error: HttpErrorResponse): string[] {
    const errorMessages: string[] = [];

    try {
      if (typeof error.error === 'string') {
        errorMessages.push(error.error);
      }

      if (error.error && typeof error.error === 'object') {
        const errorObj = error.error;

        // Handle array of errors
        if (Array.isArray(errorObj)) {
          errorObj.forEach((err) => {
            if (typeof err === 'string') {
              errorMessages.push(err);
            } else if (err.message) {
              errorMessages.push(err.message);
            }
          });
          return errorMessages;
        }

        // Handle object errors
        Object.entries(errorObj).forEach(([, value]) => {
          if (Array.isArray(value)) {
            errorMessages.push(...value.filter((v) => typeof v === 'string'));
          } else if (typeof value === 'string') {
            errorMessages.push(value);
          }
        });

        // Handle error.error.error string
        if (errorObj.error && typeof errorObj.error === 'string') {
          errorMessages.push(errorObj.error);
        }
      }

      // If no specific error message found, use status text
      if (errorMessages.length === 0 && error.statusText) {
        errorMessages.push(error.statusText);
      }

      // Special handling for 500 errors
      if (error.status === 500 && errorMessages.length === 0) {
        errorMessages.push('Internal server error');
      }
    } catch (e) {
      console.error('Error parsing error messages:', e);
      errorMessages.push('An unexpected error occurred');
    }

    return errorMessages;
  }

  private showError(message: string): void {
    this.toastr.error(message, 'Error', {
      timeOut: 5000,
      closeButton: true,
      progressBar: true,
    });
  }
}
