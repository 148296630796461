import { Injectable } from '@angular/core';
import { ChunkContext } from '../../interfaces/chunk/chunk-context.interface';
import { ToastrService } from 'ngx-toastr';

interface ModuleRegistryEntry {
  id: string;
  name: string;
  code: string;
  namespace: any;
  isLoaded: boolean;
  lastUsed: number;
  useCount: number;
  dependencies: string[];
  exports: string[];
}

interface DependencyAnalysis {
  dependencies: string[];
  missingDependencies: string[];
}

@Injectable({
  providedIn: 'root',
})
export class PythonModuleManager {
  public moduleRegistry: Map<string, ModuleRegistryEntry> = new Map();
  private pythonRuntime: any;
  private jsExternalModulesNamespaces: any;
  private moduleCounter: number = 0;
  private isInitialized: boolean = false;
  private baseNamespace: string = "chunks";

  constructor(
    private toastr: ToastrService,
  ) { }

  async initialize(runtime: any, jsExternalModulesNamespaces: any) {
    if (this.isInitialized) {
      return;
    }

    this.pythonRuntime = runtime;
    this.jsExternalModulesNamespaces = jsExternalModulesNamespaces;

    await this.setupModuleSystem();

    this.isInitialized = true;
  }

  private async setupModuleSystem() {
    const moduleSystemSetup = `
import sys
import io
import asyncio
import inspect
import traceback
from types import ModuleType
from contextlib import contextmanager

class UnifiedPythonSystem:
    """
    A unified system for managing Python module registration, execution, and output capture.
    Supports both synchronous and asynchronous code execution with proper module handling.
    """

    def __init__(self, base_namespace="chunks"):
        """Initialize the Python system with necessary storage and output handling."""
        # Namespace configuration
        self._base_namespace = base_namespace

        # Core storage for module management
        self._modules = {}        # Stores ModuleType instances
        self._module_code = {}    # Stores original source code for each module
        self._dependencies = {}   # Tracks inter-module dependencies
        self._exports = {}        # Tracks exported symbols for each module
        self._executed_modules = set()  # Tracks which modules have been fully executed
        self._module_contexts = {} # Stores module context data

        # Output handling setup
        self._original_stdout = sys.stdout
        self._original_stderr = sys.stderr

    def store_module_context(self, module_id: str, context: dict) -> str:
        context_id = f"ctx_{module_id}"
        self._module_contexts[context_id] = context
        return context_id

    def get_module_context(self, context_id: str) -> dict:
        return self._module_contexts.get(context_id)

    def setup_base_namespace(self, namespace=None):
        if namespace is None:
            namespace = {}

        # Add essential items
        namespace.update({
            '__builtins__': __builtins__,
            'asyncio': asyncio,
            'data': globals().get('data', {})
        })

        # Add chunks package to namespace
        if self._base_namespace in sys.modules:
            namespace[self._base_namespace] = sys.modules[self._base_namespace]

        # Add only executed modules
        for qualified_name, module in self._modules.items():
            if qualified_name in self._executed_modules:
                module_id = qualified_name.replace(f"{self._base_namespace}.", '')
                namespace[module_id] = module

        return namespace

    def _ensure_chunks_package(self):
        """Ensures chunks package exists and returns it"""
        if self._base_namespace not in sys.modules:
            chunks_pkg = ModuleType(self._base_namespace)
            chunks_pkg.__package__ = self._base_namespace
            chunks_pkg.__path__ = []
            chunks_pkg.__file__ = f"<virtual_{self._base_namespace}>"
            chunks_pkg.__all__ = []
            sys.modules[self._base_namespace] = chunks_pkg
            print(f"Created chunks namespace package")
        return sys.modules[self._base_namespace]

    @contextmanager
    def _capture_output(self):
        """
        Context manager for capturing stdout and stderr output.
        Creates new StringIO for each capture to avoid output accumulation.
        """
        # Create new buffer for each capture
        output_buffer = io.StringIO()

        # Store original stdout/stderr
        original_stdout = sys.stdout
        original_stderr = sys.stderr

        # Redirect output to new buffer
        sys.stdout = output_buffer
        sys.stderr = output_buffer

        try:
            yield output_buffer
        finally:
            # Restore original stdout/stderr
            sys.stdout = original_stdout
            sys.stderr = original_stderr
            # Close buffer
            output_buffer.close()

    async def register_module(self, module_id: str, name: str, code: str):
        """Register module without executing code"""
        try:
            qualified_name = f"{self._base_namespace}.{module_id}"
            print(f"\\n=== Registering Module ===")
            print(f"Module ID: {module_id}")
            print(f"Qualified name: {qualified_name}")

            # Cleanup first
            self._cleanup_module(qualified_name)

            # Create module instance
            module_obj = ModuleType(qualified_name)
            module_obj.__file__ = f"<virtual_module_{module_id}>"
            module_obj.__package__ = self._base_namespace
            module_obj.__name__ = qualified_name

            # Setup initial namespace
            namespace = self.setup_base_namespace()
            module_obj.__dict__.update(namespace)

            # Register in sys.modules
            sys.modules[qualified_name] = module_obj

            # Setup chunks package
            chunks_pkg = self._ensure_chunks_package()
            setattr(chunks_pkg, module_id, module_obj)
            if module_id not in chunks_pkg.__all__:
                chunks_pkg.__all__.append(module_id)

            # Save module info
            self._modules[qualified_name] = module_obj
            self._module_code[qualified_name] = code

            print(f"=== Module Registration Complete ===\\n")

            context = {
                'id': qualified_name,
                'module_obj': module_obj,
                'chunks_pkg': chunks_pkg,
                'namespace': module_obj.__dict__,
            }

            context_id = self.store_module_context(module_id, context)

            return {
                'id': qualified_name,
                'context_id': context_id,
                'is_loaded': True
            }

        except Exception as e:
            error_msg = f"Module registration failed: {str(e)}\\n{traceback.format_exc()}"
            print(error_msg)
            self._cleanup_module(qualified_name)
            raise RuntimeError(error_msg)

    async def execute_code(self, code: str, namespace=None, context_id=None):
        """
        Execute Python code with fresh output capture for each execution.
        Handles both synchronous and asynchronous code.
        """
        try:
            if namespace is None:
                namespace = {}
            namespace = self.setup_base_namespace(namespace)

            # Initialize result structure
            result = {
                'output': '',
                'namespace': namespace,
                'error': None,
                'exports': []
            }

            # Split code into imports and main code
            import_lines = []
            main_code_lines = []

            for line in code.split('\\n'):
                if line.strip().startswith(('import ', 'from ')):
                    import_lines.append(line)
                else:
                    main_code_lines.append(line)

            # Execute imports with fresh output capture
            if import_lines:
                import_code = '\\n'.join(import_lines)
                with self._capture_output() as buffer:
                    try:
                        exec(import_code, namespace)
                        result['output'] = buffer.getvalue() or 'Code executed successfully'
                    except Exception as e:
                        return {
                            'output': buffer.getvalue(),
                            'namespace': namespace,
                            'error': f"Import error: {str(e)}\\n{traceback.format_exc()}"
                        }

            # Execute main code with fresh output capture
            main_code = '\\n'.join(main_code_lines)
            is_async = 'async' in main_code or 'await' in main_code

            if is_async:
                indented_code = '\\n'.join('    ' + line for line in main_code.splitlines())
                func_name = f"__async_exec_{id(code)}"

                wrapped_code = (
                    f"async def {func_name}():\\n"
                    f"    globals().update(locals())\\n"
                    f"{indented_code}\\n"
                    f"    return locals()\\n\\n"
                    f"__exec_result = asyncio.ensure_future({func_name}())"
                )

                # Execute the wrapper function with fresh output capture
                with self._capture_output() as buffer:
                    try:
                        exec(wrapped_code, namespace)
                        # Get the future from namespace
                        future = namespace['__exec_result']
                        # Wait for completion
                        local_vars = await future
                        # Update namespace with new locals
                        namespace.update(local_vars)
                        result['output'] = buffer.getvalue() or 'Code executed successfully'
                        result['namespace'] = namespace
                    except Exception as e:
                        return {
                            'output': buffer.getvalue(),
                            'namespace': namespace,
                            'error': f"Async execution error: {str(e)}\\n{traceback.format_exc()}"
                        }
                    finally:
                        # Cleanup temporary names
                        namespace.pop('__exec_result', None)
                        namespace.pop(func_name, None)
            else:
                # Execute synchronous code with fresh output capture
                with self._capture_output() as buffer:
                    try:
                        exec(main_code, namespace)
                        result['output'] = buffer.getvalue() or 'Code executed successfully'
                        result['namespace'] = namespace
                    except Exception as e:
                        return {
                            'output': buffer.getvalue(),
                            'namespace': namespace,
                            'error': f"Execution error: {str(e)}\\n{traceback.format_exc()}"
                        }

            # Handle module context if present
            if context_id and not result.get('error'):
                context = self.get_module_context(context_id)
                if context:
                    module_obj = context['module_obj']
                    chunks_pkg = context['chunks_pkg']
                    module_id = context['id'].replace(f"{self._base_namespace}.", '')

                    # Update module namespace
                    module_obj.__dict__.update(result['namespace'])

                    # Update exports
                    exports = []
                    for key, value in result['namespace'].items():
                        if not key.startswith('_'):
                            if (inspect.isfunction(value) or
                                inspect.isclass(value) or
                                inspect.ismodule(value) or
                                not callable(value)):
                                exports.append(key)
                                setattr(module_obj, key, value)
                                setattr(getattr(chunks_pkg, module_id), key, value)

                    result['exports'] = exports
                    self._exports[module_obj.__name__] = exports
                    self._executed_modules.add(module_obj.__name__)

            return result

        except Exception as e:
            # Create new buffer even for error reporting
            with self._capture_output() as buffer:
                print(f"General execution error: {str(e)}\\n{traceback.format_exc()}")
                return {
                    'output': buffer.getvalue(),
                    'namespace': namespace,
                    'error': f"General execution error: {str(e)}\\n{traceback.format_exc()}"
                }

    async def import_module(self, module_name: str, requesting_module: str = None):
        try:
            # Ensure full qualified name
            if not module_name.startswith(f"{self._base_namespace}."):
                module_name = f"{self._base_namespace}.{module_name.lower()}"

            print(f"\\n=== Importing Module ===")
            print(f"Module name: {module_name}")

            if module_name not in sys.modules:
                raise ImportError(f"Module '{module_name}' not found in sys.modules")

            module_obj = sys.modules[module_name]

            # If already executed, just return the module
            if module_name in self._executed_modules:
                return {
                    'module': module_obj,
                    'exports': self._exports.get(module_name, []),
                    'error': None
                }

            # Add to dependencies if requesting module is provided
            if requesting_module:
                if requesting_module not in self._dependencies:
                    self._dependencies[requesting_module] = set()
                self._dependencies[requesting_module].add(module_name)

            return {
                'module': module_obj,
                'exports': self._exports.get(module_name, []),
                'error': None
            }

        except Exception as e:
            error_msg = f"Import failed: {str(e)}\\n{traceback.format_exc()}"
            print(error_msg)
            return {
                'module': None,
                'exports': [],
                'error': error_msg
            }

    def _update_module_exports(self, module_name: str, namespace: dict):
        print(f"\\n=== Updating Exports for {module_name} ===")
        exports = []
        print(f"Namespace keys: {list(namespace.keys())}")

        for attr_name, attr_value in namespace.items():
            if not attr_name.startswith('_'):
                if (inspect.isfunction(attr_value) or
                    inspect.isclass(attr_value) or
                    inspect.ismodule(attr_value) or
                    not callable(attr_value)):
                    exports.append(attr_name)
                    print(f"Added export: {attr_name} ({type(attr_value)})")

        self._exports[module_name] = exports
        print(f"Final exports: {exports}")
        print("=== Export Update Complete ===\\n")

    def check_circular_imports(self, module_name: str, checking_path: set = None):
        """
        Check for circular dependencies starting from a given module.
        """
        if checking_path is None:
            checking_path = set()

        if module_name in checking_path:
            return True  # Circular dependency found

        checking_path.add(module_name)

        for dep in self._dependencies.get(module_name, set()):
            if self.check_circular_imports(dep, checking_path):
                return True

        checking_path.remove(module_name)
        return False

    def setup_base_namespace(self, namespace=None):
        """
        Set up a base namespace with essential builtins and modules.
        Ensures all registered modules are available.
        """
        if namespace is None:
            namespace = {}

        # Add essential items to namespace
        namespace.update({
            '__builtins__': __builtins__,
            'asyncio': asyncio,
            'data': globals().get('data', {})
        })

        # Add all registered modules to namespace
        for module_id, module in self._modules.items():
            if module_id in self._executed_modules:
                namespace[module_id] = module
                # Also add by the module's actual name
                if hasattr(module, '__name__'):
                    namespace[module.__name__] = module

        # Add all non-private modules from sys.modules
        for module_name, module in sys.modules.items():
            if not module_name.startswith('_'):
                namespace[module_name] = module

        return namespace

    def get_module_dependencies(self, module_name: str) -> list:
        """Get all dependencies for a module."""
        return list(self._dependencies.get(module_name, set()))

    def get_module_exports(self, module_id: str) -> list:
        """Get list of exports for a module."""
        return self._exports.get(module_id, [])

    def diagnose_system_state(self):
        print("\\n=== System State Diagnostic ===")
        print(f"Base namespace: {self._base_namespace}")
        print(f"\\nRegistered Modules:")
        for module_id, module in self._modules.items():
            print(f"\\nModule: {module_id}")
            print(f"  __name__: {getattr(module, '__name__', 'N/A')}")
            print(f"  __package__: {getattr(module, '__package__', 'N/A')}")
            print(f"  Attributes: {dir(module)}")
            print(f"  Is executed: {module_id in self._executed_modules}")
            print(f"  Exports: {self._exports.get(module_id, [])}")

        print(f"\\nNamespace Package State:")
        if self._base_namespace in sys.modules:
            ns_pkg = sys.modules[self._base_namespace]
            print(f"  __all__: {getattr(ns_pkg, '__all__', [])}")
            print(f"  Attributes: {dir(ns_pkg)}")
        else:
            print("  Namespace package not registered")

        print("=== Diagnostic Complete ===\\n")

    def _cleanup_module(self, module_id: str):
        try:
            # Clean up from chunks package
            if self._base_namespace in sys.modules:
                chunks_pkg = sys.modules[self._base_namespace]
                clean_name = module_id.replace(f"{self._base_namespace}.", '')
                if hasattr(chunks_pkg, clean_name):
                    delattr(chunks_pkg, clean_name)
                if clean_name in getattr(chunks_pkg, '__all__', []):
                    chunks_pkg.__all__.remove(clean_name)

            # Remove from sys.modules
            if module_id in sys.modules:
                del sys.modules[module_id]

            # Clean internal storage
            if module_id in self._modules:
                del self._modules[module_id]
            if module_id in self._module_code:
                del self._module_code[module_id]
            if module_id in self._exports:
                del self._exports[module_id]
            if module_id in self._executed_modules:
                self._executed_modules.remove(module_id)
            if module_id in self._dependencies:
                del self._dependencies[module_id]
        except Exception as e:
            print(f"Warning: Error during module cleanup: {str(e)}")

# Create global instance
module_system = UnifiedPythonSystem("${this.baseNamespace}")
`;

    try {
      await this.pythonRuntime.runPythonAsync(moduleSystemSetup);
    } catch (error) {
      console.error('Failed to setup module system:', error);
      this.toastr.error('Failed to initialize Python system', 'Initialization Error');
      throw error;
    }
  }

  async registerModule(name: string, code: string, context?: ChunkContext): Promise<boolean> {
    const moduleId = this.sanitizeModuleName(name);

    try {
      const existingModule = this.moduleRegistry.get(moduleId);
      const registrationCode = `await module_system.register_module("${moduleId}", "${moduleId}", ${JSON.stringify(code)})`;
      const registration = await this.pythonRuntime.runPythonAsync(registrationCode);

      this.moduleRegistry.set(moduleId, {
        id: moduleId,
        name: name,
        code: code.trim(),
        namespace: registration.namespace,
        isLoaded: registration.is_loaded,
        lastUsed: Date.now(),
        useCount: existingModule ? existingModule.useCount : 0,
        dependencies: [],
        exports: registration.exports
      });

      return true;
    } catch (error) {
      const errorMessage = `Failed to register module ${name}: ${error}`;
      console.error(errorMessage);

      if (context) {
        context.addMessage(errorMessage, 'danger');
      }

      throw error;
    }
  }

  async importModule(moduleName: string, context?: ChunkContext, requestingModule?: string): Promise<any> {
    try {
      const moduleId = this.sanitizeModuleName(moduleName);
      const registryEntry = this.moduleRegistry.get(moduleId);

      if (!registryEntry) {
        throw new Error(`Module '${moduleName}' not found`);
      }

      console.log(`Importing module ${moduleName} (id: ${moduleId})`);
      console.log('Registry entry:', registryEntry);

      const importCode = `
        async def do_import():
            result = await module_system.import_module(
                "${moduleId}",
                ${requestingModule ? `"${requestingModule}"` : 'None'}
            )
            print(f"Import result: {result}")
            return result

        await do_import()
      `;

      const result = await this.pythonRuntime.runPythonAsync(importCode);

      if (result.error) {
        throw new Error(result.error);
      }

      // Update registry
      registryEntry.lastUsed = Date.now();
      registryEntry.useCount += 1;
      registryEntry.exports = result.exports;
      this.moduleRegistry.set(moduleId, registryEntry);

      return result.module;
    } catch (error) {
      const errorMessage = `Failed to import module ${moduleName}: ${error}`;
      console.error(errorMessage);
      if (context) {
        context.addMessage(errorMessage, 'danger');
      }
      throw error;
    }
  }

  async diagnoseSystemState(): Promise<void> {
    try {
      await this.pythonRuntime.runPythonAsync(
        'module_system.diagnose_system_state()'
      );
    } catch (error) {
      console.error('Diagnostic failed:', error);
    }
  }

  private generateModuleId(name: string): string {
    const sanitizedName = this.sanitizeModuleName(name);
    // TODO: think about current implementation for module ID generation
    return `${sanitizedName}_${this.moduleCounter++}`;
    // return `${sanitizedName}_1`;
  }

  public sanitizeModuleName(name: string): string {
    return name
      .toLowerCase()
      .replace(/[^a-z0-9_]/g, '_')
      .replace(/^[^a-z]/, 'mod_$&');
  }

  /**
   * Public method to analyze dependencies in Python code
   */
  analyzeDependencies(code: string): DependencyAnalysis {
    const dependencies = this.extractDependencies(code);
    const missingDependencies = dependencies.filter(
      dep => !this.moduleRegistry.has(dep)
    );

    return {
      dependencies,
      missingDependencies
    };
  }

  private async validateDependencies(
    dependencies: string[],
    context?: ChunkContext
  ): Promise<boolean> {
    for (const dep of dependencies) {
      if (!this.moduleRegistry.has(dep)) {
        const error = `Missing dependency: ${dep}`;
        if (context) {
          context.addMessage(error, 'danger');
        }
        throw new Error(error);
      }
    }
    return true;
  }

  private extractDependencies(code: string): string[] {
    const dependencies = new Set<string>();
    const lines = code.split('\n');

    for (const line of lines) {
      const trimmedLine = line.trim();

      if (!trimmedLine || !trimmedLine.includes('chunks')) {
        continue;
      }

      const directImportsMatches = trimmedLine.match(/chunks\.(\w+)\b/g);

      if (directImportsMatches) {
        directImportsMatches.forEach(match => {
          const moduleName = match.replace('chunks.', '');
          const sanitizedName = this.sanitizeModuleName(moduleName);

          if (this.moduleRegistry.has(sanitizedName)) {
            dependencies.add(moduleName);
          }
        });
      }

      const fromImportMatch = trimmedLine.match(/^from\s+chunks\s+import\s+([\w\s,]+)(?:\s+#.*)?$/);
      if (fromImportMatch?.[1]) {
        const importsList = fromImportMatch[1];
        const moduleSpecs = importsList.split(',');

        moduleSpecs.forEach(spec => {
          const moduleName = spec.trim().split(/\s+as\s+/)[0].trim();
          const sanitizedName = this.sanitizeModuleName(moduleName);

          if (moduleName && this.moduleRegistry.has(sanitizedName)) {
            dependencies.add(moduleName);
          }
        });
      }
    }

    const result = Array.from(dependencies);
    console.log('~~~~~~~ extractDependencies: ', result);
    return result;
  }

  async updateModule(
    moduleId: string,
    newCode: string,
    context: ChunkContext
  ): Promise<boolean> {
    try {
      const entry = this.moduleRegistry.get(moduleId);
      if (!entry) {
        throw new Error(`Module ${moduleId} not found`);
      }

      // Unload existing module
      await this.pythonRuntime.runPythonAsync(
        `module_manager.unload_module("${moduleId}")`
      );

      // Register updated module
      console.log('updateModule: ', entry.name);
      const success = await this.registerModule(entry.name, newCode, context);

      if (!success) {
        // Rollback to old version on failure
        await this.registerModule(entry.name, entry.code, context);
        throw new Error('Module update failed, rolled back to previous version');
      }

      return success;

    } catch (error) {
      const errorMessage = `Failed to update module ${moduleId}: ${error}`;
      console.error(errorMessage);
      if (context) {
        context.addMessage(errorMessage, 'danger');
      }
      throw error;
    }
  }

  getModuleInfo(moduleId: string): ModuleRegistryEntry | undefined {
    return this.moduleRegistry.get(moduleId);
  }

  listModules(): Array<{ id: string, name: string, exports: string[] }> {
    return Array.from(this.moduleRegistry.values())
      .map(({ id, name, exports }) => ({ id, name, exports }));
  }

  async validateModuleDependencies(dependencies: string[], context?: ChunkContext): Promise<boolean> {
    try {
      // Check all dependencies exist
      for (const dep of dependencies) {
        const moduleId = this.sanitizeModuleName(dep);
        if (!this.moduleRegistry.has(moduleId)) {
          throw new Error(`Missing dependency: ${dep}`);
        }
      }

      // Check for circular dependencies
      for (const dep of dependencies) {
        const moduleId = this.sanitizeModuleName(dep);
        const checkCode = `module_system.check_circular_imports("${moduleId}")`;
        const hasCircular = await this.pythonRuntime.runPythonAsync(checkCode);
        if (hasCircular) {
          throw new Error(`Circular dependency detected involving module: ${dep}`);
        }
      }

      return true;
    } catch (error) {
      const errorMessage = `Dependency validation failed: ${error}`;
      if (context) {
        context.addMessage(errorMessage, 'danger');
      }
      throw error;
    }
  }
}
