import { Injectable, OnDestroy } from '@angular/core';
import { DATA_TYPES, RELOAD_WASM } from '../../../constants/general.constants';
import { Table, tableFromArrays, tableFromIPC, tableFromJSON, tableToIPC } from 'apache-arrow';
import { GeneralHelpers } from '../../../helpers/general.helper';

import Module from '../../../../../assets/local_wasm/store-wasm/store-wasm';
import {
  ERROR_CONVERT_IMAGE_TO_TENSOR,
  ERROR_GET_NUMERIC_ARRAY_VIEW,
  ERROR_GET_NUMERIC_COLUMN_VIEW,
  ERROR_GET_OBJECT_INFO,
  ERROR_GET_OBJECT_JSON,
  ERROR_GET_TABLE_CELL,
  ERROR_GET_TABLE_CELL_VIEW,
  ERROR_GET_TABLE_COLUMN,
  ERROR_GET_TABLE_INFO,
  ERROR_GET_TABLE_IPC,
  ERROR_GET_TABLE_ROWS,
  ERROR_GET_TENSOR_VIEW,
  ERROR_JOIN_ARRAYS_INTO_TABLE,
  ERROR_PUT_ARRAY,
  ERROR_PUT_NUMERIC_ARRAY,
  ERROR_PUT_SCALAR,
  ERROR_REMOVE_OBJECT,
} from '../../../constants/additional-methods.constants';
import { Subscription } from 'rxjs';
import { MessageService } from '../../message/message.service';
import { ToastrService } from 'ngx-toastr';
import { ExecutionContext } from '../../../interfaces/chunk/chunk-context.interface';

@Injectable({
  providedIn: 'root',
})
export class WasmStoreService implements OnDestroy {
  // Store C API functions
  private createSampleDataWrapped: any;
  private emptyStoreWrapped: any;
  private putScalarWrapped: any;
  private putArrayWrapped: any;
  private getScalarWrapped: any;
  private getArrayWrapped: any;
  private stackScalarsIntoArrayWrapped: any;
  private putNumericArrayWrapped: any;
  private listPathsWrapped: any;
  private removeObjectWrapped: any;
  private getNumericArrayWrapped: any;
  private joinArraysIntoTableWrapped: any;
  private getObjectInfoWrapped: any;
  private getTableInfoWrapped: any;
  private getTableColumnWrapped: any;
  private getNumericColumnViewWrapped: any;
  private getTableRowWrapped: any;
  private getTableCellWrapped: any;
  private getTableCellViewWrapped: any;
  private convertImageTensorWrapped: any;
  private getNumericTensorWrapped: any;
  private putTableIPCWrapped: any;
  private getTableIPCWrapped: any;
  // ─────────────────────────────────────────────────────────────────────
  // Message subscription
  private messageSubscription!: Subscription;

  constructor(
    private messageService: MessageService,
    private toastrService: ToastrService
  ) {
    this.initStoireMethods();
    this.messageSubscription = this.messageService
      .getMessage()
      .subscribe((message: any) => {
        if (message && message.text === RELOAD_WASM) {
          this.emptyStore();
        }
      });
  }

  // eslint-disable-next-line
ngOnDestroy(): void {
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
    }
  }

  private initStoireMethods() {
    // Store C API functions
    this.createSampleDataWrapped = Module.cwrap(
      'CreateSampleData',
      'number',
      []
    );
    this.emptyStoreWrapped = Module.cwrap('EmptyStore', 'number', []);
    this.putScalarWrapped = Module.cwrap('PutScalar', 'number', [
      'number',
      'number',
      'number',
      'number',
    ]);
    this.putArrayWrapped = Module.cwrap('PutArray', 'number', [
      'number',
      'number',
      'number',
      'number',
    ]);
    this.getScalarWrapped = Module.cwrap('GetScalar', 'number', ['number']);
    this.getArrayWrapped = Module.cwrap('GetArray', 'number', ['number']);
    this.stackScalarsIntoArrayWrapped = Module.cwrap(
      'StackScalarsIntoArray',
      'number',
      ['number', 'number', 'number']
    );
    this.putNumericArrayWrapped = Module.cwrap('PutNumericArray', 'number', [
      'number',
      'number',
      'number',
      'number',
    ]);
    this.listPathsWrapped = Module.cwrap('ListPaths', 'number', []);
    this.removeObjectWrapped = Module.cwrap('RemoveObject', 'number', [
      'number',
    ]);
    this.getNumericArrayWrapped = Module.cwrap('GetNumericArray', 'number', [
      'number',
    ]);
    this.joinArraysIntoTableWrapped = Module.cwrap(
      'JoinArraysIntoTable',
      'number',
      ['number', 'number', 'number']
    );
    this.getObjectInfoWrapped = Module.cwrap('GetObjectInfo', 'number', [
      'number',
    ]);
    this.getTableInfoWrapped = Module.cwrap('GetTableInfo', 'number', [
      'number',
    ]);
    this.getTableColumnWrapped = Module.cwrap('GetTableColumn', 'number', [
      'number',
      'number',
    ]);
    this.getNumericColumnViewWrapped = Module.cwrap(
      'GetNumericColumnView',
      'number',
      ['number', 'number']
    );
    this.getTableRowWrapped = Module.cwrap('GetTableRows', 'number', [
      'number',
      'number',
      'number',
    ]);
    this.getTableCellWrapped = Module.cwrap('GetTableCell', 'number', [
      'number',
      'number',
      'number',
    ]);
    this.getTableCellViewWrapped = Module.cwrap('GetTableCellView', 'number', [
      'number',
      'number',
      'number',
      'number',
    ]);
    this.convertImageTensorWrapped = Module.cwrap(
      'ConvertImageTensor',
      'number',
      ['number', 'number', 'number']
    );
    this.getNumericTensorWrapped = Module.cwrap('GetNumericTensor', 'number', [
      'number',
    ]);
    this.putTableIPCWrapped = Module.cwrap('PutTableIPC', 'number', [
      'number',
      'number',
      'number',
    ]);
    this.getTableIPCWrapped = Module.cwrap('GetTableIPC', 'number', [
      'number',
      'number',
    ]);
  }

  // ─────────────────────────────────────────────────────────────────────────────
  // Public methods
  // ─────────────────────────────────────────────────────────────────────────────

  // Create a named array from a JS array
  public putNumericArray(data: any[], context: ExecutionContext) {
    if (!Array.isArray(data) || data.length < 3) {
      context.addMessage(ERROR_PUT_NUMERIC_ARRAY, 'danger');
      return;
    }
    const key = data[0];
    const input_array = data[1];
    const type = data[2];

    // Validate key is a non-empty string
    if (typeof key !== 'string' || key.trim() === '') {
      context.addMessage(
        'Invalid key: Key must be a non-empty string.',
        'danger'
      );
      return;
    }

    // Validate input_array is an instance of TypedArray
    if (
      !(
        input_array instanceof Uint8Array ||
        input_array instanceof Int32Array ||
        input_array instanceof Float32Array ||
        input_array instanceof Float64Array
      )
    ) {
      context.addMessage(
        'Invalid input array: Must be a typed array.',
        'danger'
      );
      return;
    }

    // Validate type is a valid key in DATA_TYPES
    if (!Object.values(DATA_TYPES).includes(type)) {
      context.addMessage(
        'Invalid type: Type must be one of the supported data types.',
        'danger'
      );
      return;
    }

    // Write the key and array into the WASM heap
    const bytes_per_element = input_array.BYTES_PER_ELEMENT;
    const array_ptr = Module._malloc(input_array.length * bytes_per_element);
    if (type == DATA_TYPES.uint8) {
      Module.HEAPU8.set(input_array, array_ptr / bytes_per_element);
    } else if (type == DATA_TYPES.int32) {
      Module.HEAP32.set(input_array, array_ptr / bytes_per_element);
    } else if (type == DATA_TYPES.float) {
      Module.HEAPF32.set(input_array, array_ptr / bytes_per_element);
    } else if (type == DATA_TYPES.double) {
      Module.HEAPF64.set(input_array, array_ptr / bytes_per_element);
    } else {
      console.log('Unsupported type');
      return;
    }
    const key_ptr = this.makeCString(key);
    // Call the private to store the array
    try {
      this.putNumericArrayWrapped(key_ptr, array_ptr, input_array.length, type);
    } catch (error) {
      console.log('Error putting numeric array', error);
      this.toastrService.error('Error putting numeric array', error as string);
    }
    Module._free(array_ptr);
    Module._free(key_ptr);
  }

  // Stack scalars into a 1D array
  public stackScalarsIntoArray(data: any[], context: ExecutionContext) {
    if (!Array.isArray(data) || data.length < 3) {
      context.addMessage(ERROR_PUT_ARRAY, 'danger');
      return;
    }
    const arr_key = data[0];
    const scalar_keys = data[1];
    const delete_on_create = data[2];
    // Validate arr_key is a non-empty string
    if (typeof arr_key !== 'string' || arr_key.trim() === '') {
      context.addMessage(
        'Invalid arr_key: Key must be a non-empty string.',
        'danger'
      );
      return;
    }

    // Validate scalar_keys is an array of non-empty strings
    if (
      !Array.isArray(scalar_keys) ||
      scalar_keys.some((key) => typeof key !== 'string' || key.trim() === '')
    ) {
      context.addMessage(
        'Invalid scalar_keys: Each key must be a non-empty string.',
        'danger'
      );
      return;
    }

    // Validate delete_on_create is a boolean
    if (typeof delete_on_create !== 'boolean') {
      context.addMessage(
        'Invalid delete_on_create: Must be a boolean.',
        'danger'
      );
      return;
    }
    return this.createObjectFromKeys(
      arr_key,
      scalar_keys,
      delete_on_create,
      this.stackScalarsIntoArrayWrapped
    );
  }

  // Create a named table from JS arrays already in the Store
  public joinArraysIntoTable(data: any[], context: ExecutionContext) {
    if (!Array.isArray(data) || data.length < 3) {
      context.addMessage(ERROR_JOIN_ARRAYS_INTO_TABLE, 'danger');
      return;
    }

    const table_key = data[0];
    const array_keys = data[1];
    const delete_on_create = data[2];

    // Validate table_key as a non-empty string
    if (typeof table_key !== 'string' || table_key.trim() === '') {
      context.addMessage(
        'Invalid table_key: Must be a non-empty string.',
        'danger'
      );
      return;
    }

    // Validate array_keys as an array of non-empty strings
    if (
      !Array.isArray(array_keys) ||
      array_keys.some((key) => typeof key !== 'string' || key.trim() === '')
    ) {
      context.addMessage(
        'Invalid array_keys: Each element must be a non-empty string.',
        'danger'
      );
      return;
    }

    // Validate delete_on_create as a boolean
    if (typeof delete_on_create !== 'boolean') {
      context.addMessage(
        'Invalid delete_on_create: Must be a boolean.',
        'danger'
      );
      return;
    }

    const result = this.createObjectFromKeys(
      table_key,
      array_keys,
      delete_on_create,
      this.joinArraysIntoTableWrapped
    );
    return result;
  }

  // Populate the store with sample data
  public createSampleData() {
    let error_code = -1;
    try {
      error_code = this.createSampleDataWrapped();
    } catch (error) {
      console.log('Error creating sample data', error);
      this.toastrService.error('Error creating sample data', error as string);
    }

    return error_code;
  }

  // Empty all data from in the store
  public emptyStore() {
    let error_code = -1;

    try {
      error_code = this.emptyStoreWrapped();
    } catch (error) {
      console.log('Error emptying store', error);
      this.toastrService.error('Error emptying store', error as string);
    }

    return error_code;
  }

  // Empty all data from in the store
  public removeObject(data: any[], context: ExecutionContext) {
    if (!Array.isArray(data) || data.length < 1) {
      context.addMessage(ERROR_REMOVE_OBJECT, 'danger');
      return;
    }
    const key = data[0];
    // Validate key as a non-empty string
    if (typeof key !== 'string' || key.trim() === '') {
      context.addMessage('Invalid key: Must be a non-empty string.', 'danger');
      return;
    }
    const key_ptr = this.makeCString(key);
    let error_code = -1;
    try {
      error_code = this.removeObjectWrapped(key_ptr);
    } catch (error) {
      console.log('Error removing object', error);
      this.toastrService.error('Error removing object', error as string);
    }
    Module._free(key_ptr);
    return error_code;
  }

  // Return a JS object with the contents of the Store
  public listStore() {
    let list_ptr = -1;
    try {
      list_ptr = this.listPathsWrapped();
    } catch (error) {
      console.log('Error listing store', error);
      this.toastrService.error('Error listing store', error as string);
    }
    const obj = this.parseJsonResult(list_ptr);
    // Memory allocated in C++ but freed here
    Module._free(list_ptr);
    return obj;
  }

  // Add a scalar to the store
  // key: string
  // val: a string that should be parsed according to format to obtain the value
  // type: DataType
  // append: boolean
  // format: a string with the locale-specific template for parsing the value
  // return: 0 if successful, -1 if error
  public putScalar(data: any[], context: ExecutionContext) {
    if (!Array.isArray(data) || data.length < 4) {
      context.addMessage(ERROR_PUT_SCALAR, 'danger');
      return;
    }

    const key = data[0];
    const val = data[1];
    const type = data[2];
    const format = data[3];

    // Validate key as a non-empty string
    if (typeof key !== 'string' || key.trim() === '') {
      context.addMessage('Invalid key: Must be a non-empty string.', 'danger');
      return;
    }

    // Validate val as a string (could be empty since it represents scalar value)
    if (typeof val !== 'string') {
      context.addMessage('Invalid val: Must be a string.', 'danger');
      return;
    }

    // Validate format as a string
    if (typeof format !== 'string') {
      context.addMessage('Invalid format: Must be a string.', 'danger');
      return;
    }

    const key_ptr = this.makeCString(key);
    const val_ptr = this.makeCString(val);
    const format_ptr = this.makeCString(format);
    let error_code = -1;
    try {
      error_code = this.putScalarWrapped(key_ptr, val_ptr, type, format_ptr);
    } catch (error) {
      console.log('Error putting scalar', error);
      this.toastrService.error('Error putting scalar', error as string);
    }

    Module._free(key_ptr);
    Module._free(val_ptr);
    Module._free(format_ptr);

    return error_code;
  }

  public putArray(data: any[], context: ExecutionContext) {
    if (!Array.isArray(data) || data.length < 4) {
      context.addMessage(ERROR_PUT_ARRAY, 'danger');
      return;
    }

    const key = data[0];
    const arr_json = data[1];
    const type = data[2];
    const format = data[3];

    // Key validation
    if (typeof key !== 'string' || key.trim() === '') {
      context.addMessage('Invalid key: Must be a non-empty string.', 'danger');
      return;
    }

    // arr_json validation - should be a string representing an array in JSON format
    if (typeof arr_json !== 'string') {
      context.addMessage(
        'Invalid arr_json: Must be a string representing JSON.',
        'danger'
      );
      return;
    }

    // Format validation
    if (typeof format !== 'string') {
      context.addMessage('Invalid format: Must be a string.', 'danger');
      return;
    }

    const key_ptr = this.makeCString(key);
    const arr_json_ptr = this.makeCString(arr_json);
    const format_ptr = this.makeCString(format);
    let error_code = -1;
    try {
      error_code = this.putArrayWrapped(
        key_ptr,
        arr_json_ptr,
        type,
        format_ptr
      );
    } catch (error) {
      console.log('Error putting array', error);
      this.toastrService.error('Error putting array', error as string);
    }

    Module._free(key_ptr);
    Module._free(arr_json_ptr);
    Module._free(format_ptr);

    if (error_code == -1) {
      // Handle the error accordingly
      context.addMessage('Failed to put array.', 'danger');
    }

    return error_code;
  }

  // Get the information about an object in the store by key_ptr (on the WASM HEAP)
  public getObjectInfo(data: any[], context: ExecutionContext) {
    if (!Array.isArray(data) || data.length < 1) {
      context.addMessage(ERROR_GET_OBJECT_INFO, 'danger');
      return;
    }
    const key = data[0];

    // Validate key as a non-empty string
    if (typeof key !== 'string' || key.trim() === '') {
      context.addMessage('Invalid key: Must be a non-empty string.', 'danger');
      return;
    }

    const key_ptr = this.makeCString(key);
    const obj = this.getObjectInfoImpl(key_ptr);
    Module._free(key_ptr);

    // Optionally, include additional error handling if `obj` is null or not as expected
    if (!obj) {
      context.addMessage(
        'Failed to get object info: Object does not exist or key is incorrect.',
        'danger'
      );
    }

    return obj;
  }

  // Get a scalar or array from the store in JSON format
  public getObjectJSON(data: any[], context: ExecutionContext) {
    if (!Array.isArray(data) || data.length < 3) {
      context.addMessage(ERROR_GET_OBJECT_JSON, 'danger');
      return;
    }

    const key = data[0];
    const format = data[1];
    const array = data[2];

    // Validate key as a non-empty string
    if (typeof key !== 'string' || key.trim() === '') {
      context.addMessage('Invalid key: Must be a non-empty string.', 'danger');
      return;
    }

    // Validate format as a string
    if (typeof format !== 'string') {
      context.addMessage('Invalid format: Must be a string.', 'danger');
      return;
    }

    // Validate array as a boolean
    if (typeof array !== 'boolean') {
      context.addMessage('Invalid array flag: Must be a boolean.', 'danger');
      return;
    }

    const key_ptr = this.makeCString(key);
    const format_ptr = this.makeCString(format);
    let result_ptr = null;

    if (array) {
      try {
        result_ptr = this.getArrayWrapped(key_ptr, format_ptr);
      } catch (error) {
        console.log('Error getting array', error);
        this.toastrService.error('Error getting array', error as string);
      }
    } else {
      try {
        result_ptr = this.getScalarWrapped(key_ptr, format_ptr);
      } catch (error) {
        console.log('Error getting scalar', error);
        this.toastrService.error('Error getting scalar', error as string);
      }
    }

    if (!result_ptr) {
      context.addMessage(
        'Failed to get object JSON: Operation returned null.',
        'danger'
      );
      Module._free(format_ptr);
      Module._free(key_ptr);
      return;
    }

    const obj = this.parseJsonResult(result_ptr);

    Module._free(result_ptr);
    Module._free(format_ptr);
    Module._free(key_ptr);

    return obj;
  }

  // Return a JS typed array (zero-copy) view from a named array in the Store
  public getNumericArrayView(kdata: any[], context: ExecutionContext) {
    if (!Array.isArray(kdata) || kdata.length < 1) {
      context.addMessage(ERROR_GET_NUMERIC_ARRAY_VIEW, 'danger');
      return null;
    }

    const key = kdata[0];

    // Validate key as a non-empty string
    if (typeof key !== 'string' || key.trim() === '') {
      context.addMessage('Invalid key: Must be a non-empty string.', 'danger');
      return null;
    }

    const key_ptr = this.makeCString(key);
    const info = this.getObjectInfoImpl(key_ptr);

    if (
      !info ||
      typeof info.dtype === 'undefined' ||
      !Array.isArray(info.shape) ||
      info.shape.length === 0
    ) {
      // Error handling if object info is invalid or not as expected
      context.addMessage(
        'Failed to get object info or object info is invalid.',
        'danger'
      );
      Module._free(key_ptr);
      return null;
    }

    let array_ptr = -1;

    try {
      array_ptr = this.getNumericArrayWrapped(key_ptr);
    } catch (error) {
      console.log('Error getting numeric array', error);
      this.toastrService.error('Error getting numeric array', error as string);
    }

    if (!array_ptr) {
      // Handle potential error if array_ptr is null or operation failed
      context.addMessage('Failed to get numeric array.', 'danger');
      Module._free(key_ptr);
      return null;
    }

    const array = this.createTypedArray(array_ptr, info.dtype, info.shape[0]);

    Module._free(key_ptr);

    return array;
  }

  // Return a JS object with the content
  public getTableInfo(data: any[], context: ExecutionContext) {
    // Validate that data is an array with at least one element
    if (!Array.isArray(data) || data.length < 1) {
      context.addMessage(ERROR_GET_TABLE_INFO, 'danger');
      return null;
    }

    const table_name = data[0];

    // Validate table_name as a non-empty string
    if (typeof table_name !== 'string' || table_name.trim() === '') {
      context.addMessage(
        'Invalid table name: Must be a non-empty string.',
        'danger'
      );
      return null;
    }

    const key_ptr = this.makeCString(table_name);
    const obj = this.getTableInfoAux(key_ptr);

    // Optionally, you can add additional checks here if obj is null or not as expected
    if (!obj) {
      context.addMessage(
        'Failed to get table info: Table does not exist or table name is incorrect.',
        'danger'
      );
    }

    Module._free(key_ptr);

    return obj;
  }

  public getTableRows(data: any[], context: ExecutionContext) {
    if (!Array.isArray(data) || data.length < 3) {
      context.addMessage(ERROR_GET_TABLE_ROWS, 'danger');
      return null;
    }

    const tbl_name = data[0];
    const start_idx = data[1];
    const end_idx = data[2];

    if (typeof tbl_name !== 'string' || tbl_name.trim() === '') {
      context.addMessage(
        'Invalid table name: Must be a non-empty string.',
        'danger'
      );
      return null;
    }

    if (typeof start_idx !== 'number' || typeof end_idx !== 'number') {
      context.addMessage(
        'Invalid index values: Start and end indices must be numbers.',
        'danger'
      );
      return null;
    }

    const key_ptr = this.makeCString(tbl_name);
    let result_ptr = -1;
    try {
      result_ptr = this.getTableRowWrapped(key_ptr, start_idx, end_idx);
    } catch (error) {
      console.log('Error getting table rows', error);
      this.toastrService.error('Error getting table rows', error as string);
    }
    const obj = this.parseJsonResult(result_ptr);
    Module._free(key_ptr);
    return obj;
  }

  public getTableColumn(data: any[], context: ExecutionContext) {
    if (!Array.isArray(data) || data.length < 2) {
      context.addMessage(ERROR_GET_TABLE_COLUMN, 'danger');
      return null;
    }

    const tbl_name = data[0];
    const col_name = data[1];

    if (
      typeof tbl_name !== 'string' ||
      tbl_name.trim() === '' ||
      typeof col_name !== 'string' ||
      col_name.trim() === ''
    ) {
      context.addMessage(
        'Invalid table or column name: Both must be non-empty strings.',
        'danger'
      );
      return null;
    }

    const key_ptr = this.makeCString(tbl_name);
    const col_ptr = this.makeCString(col_name);
    let result_ptr = -1;
    try {
      result_ptr = this.getTableColumnWrapped(key_ptr, col_ptr);
    } catch (error) {
      console.log('Error getting table column', error);
      this.toastrService.error('Error getting table column', error as string);
    }
    const obj = this.parseJsonResult(result_ptr);
    Module._free(key_ptr);
    Module._free(col_ptr);
    return obj;
  }

  public getTableCell(data: any[], context: ExecutionContext) {
    if (!Array.isArray(data) || data.length < 3) {
      context.addMessage(ERROR_GET_TABLE_CELL, 'danger');
      return null;
    }

    const tbl_name = data[0];
    const col_name = data[1];
    const idx = data[2];

    if (
      typeof tbl_name !== 'string' ||
      tbl_name.trim() === '' ||
      typeof col_name !== 'string' ||
      col_name.trim() === '' ||
      typeof idx !== 'number'
    ) {
      context.addMessage(
        'Invalid input: Table name and column name must be non-empty strings, and index must be a number.',
        'danger'
      );
      return null;
    }

    const key_ptr = this.makeCString(tbl_name);
    const col_ptr = this.makeCString(col_name);
    let result_ptr = -1;
    try {
      result_ptr = this.getTableCellWrapped(key_ptr, col_ptr, idx);
    } catch (error) {
      console.log('Error getting table cell', error);
      this.toastrService.error('Error getting table cell', error as string);
    }
    const obj = this.parseJsonResult(result_ptr);
    Module._free(key_ptr);
    Module._free(col_ptr);
    return obj;
  }

  public getNumericColumnView(data: any[], context: ExecutionContext) {
    if (
      !Array.isArray(data) ||
      data.length < 3 ||
      typeof data[0] !== 'string' ||
      typeof data[1] !== 'object' ||
      typeof data[2] !== 'number'
    ) {
      context.addMessage(ERROR_GET_NUMERIC_COLUMN_VIEW, 'danger');
      return null;
    }

    const tbl_name = data[0];
    const col = data[1];
    const num_rows = data[2];

    if (typeof col.name !== 'string' || !Object.prototype.hasOwnProperty.call(DATA_TYPES, col.type)) {
      context.addMessage(ERROR_GET_NUMERIC_COLUMN_VIEW, 'danger');
      return null;
    }

    const tbl_key_ptr = this.makeCString(tbl_name);
    const col_key_ptr = this.makeCString(col.name);
    let array_ptr = -1;
    try {
      array_ptr = this.getNumericColumnViewWrapped(
        tbl_key_ptr,
        col_key_ptr,
        num_rows
      );
    } catch (error) {
      console.log('Error getting numeric column view', error);
      this.toastrService.error(
        'Error getting numeric column view',
        error as string
      );
    }
    const array = this.createTypedArray(
      array_ptr,
      DATA_TYPES[col.type],
      num_rows
    );

    Module._free(tbl_key_ptr);
    Module._free(col_key_ptr);
    return array;
  }

  public getTableCellView(data: any[], context: ExecutionContext) {
    if (
      !Array.isArray(data) ||
      data.length < 3 ||
      typeof data[0] !== 'string' ||
      typeof data[1] !== 'string' ||
      typeof data[2] !== 'number'
    ) {
      context.addMessage(ERROR_GET_TABLE_CELL_VIEW, 'danger');
      return null;
    }

    const tbl_name = data[0];
    const col_name = data[1];
    const r = data[2];

    const tbl_key_ptr = this.makeCString(tbl_name);
    const col_key_ptr = this.makeCString(col_name);
    const size_ptr = Module._malloc(Int32Array.BYTES_PER_ELEMENT * 2);
    let array_ptr = -1;
    try {
      array_ptr = this.getTableCellViewWrapped(
        tbl_key_ptr,
        col_key_ptr,
        r,
        size_ptr
      );
    } catch (error) {
      console.log('Error getting table cell view', error);
      this.toastrService.error(
        'Error getting table cell view',
        error as string
      );
    }

    const size_result = new Int32Array(Module.HEAP32.buffer, size_ptr, 2);
    const array = this.createTypedArray(
      array_ptr,
      size_result[1],
      size_result[0]
    );

    Module._free(tbl_key_ptr);
    Module._free(col_key_ptr);
    Module._free(size_ptr);
    return array;
  }

  public convertImageToTensor(data: any[], context: ExecutionContext) {
    if (
      !Array.isArray(data) ||
      data.length < 2 ||
      typeof data[0] !== 'string' ||
      typeof data[1] !== 'string'
    ) {
      context.addMessage(ERROR_CONVERT_IMAGE_TO_TENSOR, 'danger');
      return;
    }

    const img_arr_name = data[0];
    const image_ten_name = data[1];

    const img_arr_name_ptr = this.makeCString(img_arr_name);
    const img_ten_name_ptr = this.makeCString(image_ten_name);

    let error_code = -1;
    try {
      error_code = this.convertImageTensorWrapped(
        img_arr_name_ptr,
        img_ten_name_ptr,
        img_arr_name.length
      );
    } catch (error) {
      console.log('Error converting image to tensor', error);
      this.toastrService.error(
        'Error converting image to tensor',
        error as string
      );
    }
    if (error_code === -1) {
      context.addMessage('Failed to convert image to tensor.', 'danger');
      return;
    }

    Module._free(img_arr_name_ptr);
    Module._free(img_ten_name_ptr);
  }

  public getTensorView(data: any[], context: ExecutionContext) {
    if (
      !Array.isArray(data) ||
      data.length < 1 ||
      typeof data[0] !== 'string'
    ) {
      context.addMessage(ERROR_GET_TENSOR_VIEW, 'danger');
      return null;
    }

    const ten_name = data[0];
    const ten_name_ptr = this.makeCString(ten_name);
    const info = this.getObjectInfoImpl(ten_name_ptr);
    if (!info || !info.shape || !info.dtype) {
      context.addMessage(ERROR_GET_TENSOR_VIEW, 'danger');
      Module._free(ten_name_ptr);
      return null;
    }

    let ten_ptr = -1;
    try {
      ten_ptr = this.getNumericTensorWrapped(ten_name_ptr);
    } catch (error) {
      console.log('Error getting tensor view', error);
      this.toastrService.error('Error getting tensor view', error as string);
    }
    const ten_len: number = info.shape.reduce((a: number, b: number) => a * b, 1);
    const array = this.createTypedArray(ten_ptr, info.dtype, ten_len);
    Module._free(ten_name_ptr);

    return {
      data: array as unknown as any[],
      shape: info.shape as number[],
      strides: info.strides as number[],
      calculate_index: (I: number[]) =>
        I.reduce((acc, val, idx) => acc + val * info.strides[idx], 0),
    };
  }

  public putTableIPC(data: any[], context: ExecutionContext) {
    if (
      !Array.isArray(data) ||
      data.length < 2 ||
      typeof data[0] !== 'string'
    ) {
      context.addMessage('Invalid input for putTableIPC', 'danger');
      return;
    }

    const table_name = data[0];
    const table = data[1];

    if (typeof table !== 'string' && !(table instanceof Table)) {
      context.addMessage('Invalid table data for putTableIPC', 'danger');
      return;
    }

    let table_ipc: Uint8Array;

    if (
      typeof table === 'string' &&
      GeneralHelpers.canBeParsedToNumberArray(table)
    ) {
      const numberArray = table.split(',').map(Number);
      table_ipc = new Uint8Array(numberArray);
    } else {
      const parsedTable = tableFromIPC(
        table instanceof Table ? tableToIPC(table) : table
      );
      table_ipc = tableToIPC(parsedTable);
    }

    if (table_ipc == null) {
      console.log('Error converting table to IPC');
      return;
    }

    const key_ptr = this.makeCString(table_name);
    const bytes_per_element = table_ipc.BYTES_PER_ELEMENT;
    const array_ptr = Module._malloc(table_ipc.length * bytes_per_element);
    Module.HEAPU8.set(table_ipc, array_ptr / bytes_per_element);

    let result = -1;
    try {
      result = this.putTableIPCWrapped(key_ptr, array_ptr, table_ipc.length);
    } catch (error) {
      console.log('Error putting table', error);
      this.toastrService.error('Error putting table', error as string);
    }

    Module._free(key_ptr);
    Module._free(array_ptr);

    if (result === -1) {
      context.addMessage('Error putting table', 'danger');
    }

    return result;
  }

  public getTableIPC(data: any[], context: ExecutionContext) {
    if (
      !Array.isArray(data) ||
      data.length < 1 ||
      typeof data[0] !== 'string'
    ) {
      context.addMessage(ERROR_GET_TABLE_IPC, 'danger');
      return null;
    }
    const table_name = data[0];
    const dtype = 2; // uint8
    const key_ptr = this.makeCString(table_name);
    const size_ptr = Module._malloc(4); // Allocate one int32
    let table_ptr = -1;
    try {
      table_ptr = this.getTableIPCWrapped(key_ptr, dtype);
    } catch (error) {
      console.log('Error getting table', error);
      this.toastrService.error('Error getting table', error as string);
    }
    const size_result = new Int32Array(Module.HEAP32.buffer, size_ptr, 1);
    // Get a view of the SharedArrayBuffer
    const table_arr = this.createTypedArray(table_ptr, dtype, size_result[0]);
    // Convert the SharedArrayBuffer to an ArrayBuffer
    const table_buf = new ArrayBuffer(size_result[0]);
    new Uint8Array(table_buf).set(table_arr as any);
    let table: any = tableFromIPC(table_buf);
    table = tableToIPC(table as Table<any>);
    Module._free(key_ptr);
    Module._free(size_ptr);
    console.log(table);
    return table;
  }

  public tableFromArrays(
    data: any[],
    context: ExecutionContext
  ): Uint8Array | null {
    if (!Array.isArray(data) || data.length === 0) {
      context.addMessage('Invalid input for tableFromArrays', 'danger');
      return null;
    }

    try {
      const input = data[0];
      if (typeof input !== 'object' || input === null) {
        context.addMessage('Input must be an object of arrays', 'danger');
        return null;
      }

      const table = tableFromArrays(input);
      const tableIPC = tableToIPC(table);
      return tableIPC;
    } catch (error) {
      console.error('Error in tableFromArrays:', error);
      context.addMessage(
        `Error creating table from arrays: ${error}`,
        'danger'
      );
      return null;
    }
  }

  public tableFromJSON(
    data: any[],
    context: ExecutionContext
  ): Uint8Array | null {
    if (!Array.isArray(data) || data.length === 0) {
      context.addMessage('Invalid input for tableFromJSON', 'danger');
      return null;
    }

    try {
      const array = data[0];
      if (!Array.isArray(array)) {
        context.addMessage('Input must be an array of objects', 'danger');
        return null;
      }

      const table = tableFromJSON(array);
      return tableToIPC(table);
    } catch (error) {
      console.error('Error in tableFromJSON:', error);
      context.addMessage(`Error creating table from JSON: ${error}`, 'danger');
      return null;
    }
  }

  // ─────────────────────────────────────────────────────────────────────────────
  // Private methods
  // ─────────────────────────────────────────────────────────────────────────────

  // Create a null-terminated C string from a JS string
  private makeCString(key: string) {
    const key_str = new Uint8Array(GeneralHelpers.toUTF8Array(key + '\0')); // null-terminated string for C
    const bytes_per_element = key_str.BYTES_PER_ELEMENT;
    const key_ptr = Module._malloc(key_str.length * bytes_per_element);
    Module.HEAPU8.set(key_str, key_ptr / bytes_per_element);
    return key_ptr;
  }

  // Calculate the length of a null-terminated C string
  private getCStringLen(str_ptr: number) {
    let str_len = 0;
    while (Module.HEAPU8[str_ptr + str_len] != 0) {
      str_len++;
    }
    return str_len;
  }

  private parseJsonResult(result_ptr: number) {
    if (result_ptr == null) {
      return null;
    }
    const num_items = this.getCStringLen(result_ptr);
    let obj = null;
    if (num_items > 0) {
      const result_array = new Uint8Array(
        Module.HEAP32.buffer,
        result_ptr,
        num_items
      );
      const result = new TextDecoder().decode(new Uint8Array(result_array)); // decode a copy
      obj = JSON.parse(result);
    }
    return obj;
  }

  // Create a typed array from a pointer to a C array in the WASM heap
  private createTypedArray(array_ptr: any, dtype: any, arr_len: any) {
    // See `DataType` for the type codes
    let arr = null;
    if (dtype == 2) {
      arr = new Uint8Array(Module.HEAP32.buffer, array_ptr, arr_len);
    } else if (dtype == 7) {
      arr = new Int32Array(Module.HEAP32.buffer, array_ptr, arr_len);
    } else if (dtype == 11) {
      arr = new Float32Array(Module.HEAP32.buffer, array_ptr, arr_len);
    } else if (dtype == 12) {
      arr = new Float64Array(Module.HEAP32.buffer, array_ptr, arr_len);
    } else {
      console.log('Unsupported type', dtype);
      return;
    }
    return arr;
  }

  // Process a list of symbols into a private
  private createObjectFromKeys(
    key: any,
    keys: any,
    delete_on_create: any,
    wrappedFunc: any
  ) {
    const argc = keys.length + 1;
    const argv = [];
    // Add the arguments: new_obj_key, key1, ..., keyn
    argv.push(this.makeCString(key));
    for (const k of keys) {
      argv.push(this.makeCString(k));
    }
    // Create the array of pointers to the arguments
    const argv_arr = new Uint32Array(argv);
    const argv_ptr = Module._malloc(argc * argv_arr.BYTES_PER_ELEMENT);
    Module.HEAPU32.set(argv_arr, argv_ptr / argv_arr.BYTES_PER_ELEMENT);
    let error_code = -1;
    try {
      error_code = wrappedFunc(argv_ptr, argc, delete_on_create);
    } catch (error) {
      console.log('Error creating object from keys', error);
      this.toastrService.error(
        'Error creating object from keys',
        error as string
      );
    }
    Module._free(argv_ptr);
    for (const k in argv) {
      Module._free(argv[k]);
    }
    return error_code;
  }

  private getObjectInfoImpl(key_ptr: any) {
    let result_ptr = -1;
    try {
      result_ptr = this.getObjectInfoWrapped(key_ptr);
    } catch (error) {
      console.log('Error getting object info', error);
      this.toastrService.error('Error getting object info', error as string);
    }
    const obj = this.parseJsonResult(result_ptr);
    Module._free(result_ptr);
    return obj;
  }

  private getTableInfoAux(key_ptr: any) {
    let result_ptr = -1;
    try {
      result_ptr = this.getTableInfoWrapped(key_ptr);
    } catch (error) {
      console.log('Error getting table info', error);
      this.toastrService.error('Error getting table info', error as string);
    }
    const obj = this.parseJsonResult(result_ptr);
    Module._free(result_ptr);
    return obj;
  }

  private getDType(dtype: number): string {
    switch (dtype) {
      case DATA_TYPES.na:
        return 'na';
      case DATA_TYPES.bool:
        return 'bool';
      case DATA_TYPES.uint8:
        return 'uint8';
      case DATA_TYPES.int32:
        return 'int32';
      case DATA_TYPES.float:
        return 'float';
      case DATA_TYPES.double:
        return 'double';
      case DATA_TYPES.string:
        return 'string';
      case DATA_TYPES.binary:
        return 'binary';
      case DATA_TYPES.timestamp:
        return 'timestamp';
      case DATA_TYPES.list:
        return 'list';
      default:
        return 'unknown';
    }
  }

  private getOType(otype: number): string {
    switch (otype) {
      case 0:
        return 'scalar';
      case 1:
        return 'array';
      case 2:
        return 'tensor';
      case 3:
        return 'table';
      default:
        return 'unknown';
    }
  }
}
