import {
  Component,
  Inject,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CLOSE_URL_DIALOG, VALID_URL } from '../../constants/general.constants';
import { Dialog } from '../../interfaces/create-dialog.interface';

import { MessageService } from '../../services/message/message.service';

import {
  distinctUntilChanged,
  EMPTY,
  fromEvent,
  Observable,
  BehaviorSubject,
} from 'rxjs';
import { debounceTime, map, startWith, switchMap, tap } from 'rxjs';
import {
  CdnJsModule,
  CdnJsModuleResult,
  JsDelivrModuleResult,
  Version,
} from '../../interfaces/cdn-module.interface';
import { CdnModuleService } from '../../services/cdn-module/cdn-module.service';

import { ToastrService } from 'ngx-toastr';
import { AutoUnsubscribe } from '../../decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-url-dialog',
  templateUrl: './url-dialog.component.html',
  styleUrls: ['./url-dialog.component.scss'],
})
export class UrlDialogComponent implements AfterViewInit, OnDestroy {
  // ─────────────────────────────────────────────────────────────────────
  // Cdn js autocomplete
  public cdnModuleAutocompleteData!: Observable<CdnJsModuleResult[]> | any;
  public cdnModuleLoading: boolean = false;
  public noCdnModuleFound: boolean = false;
  public cdnModuleLoadById: boolean = false;
  @ViewChild('cdnModuleAutocompleteInput')
  cdnModuleAutocompleteInput!: ElementRef;

  // ─────────────────────────────────────────────────────────────────────
  // JsDelivr Autocomplete
  public jsdelivrModuleAutocompleteData!:
    | Observable<JsDelivrModuleResult[]>
    | any;
  public jsdelivrModuleLoading: boolean = false;
  public noJsdelivrModuleFound: boolean = false;
  public jsdelivrModuleLoadById: boolean = false;
  @ViewChild('jsdelivrModuleAutocompleteInput')
  jsdelivrModuleAutocompleteInput!: ElementRef;
  // ─────────────────────────────────────────────────────────────────────────────

  // PyPi Autocomplete
  public pyPiModuleAutocompleteData!: Observable<any[]> | any;
  public pyPiModuleLoading: boolean = false;
  public noPyPiModuleFound: boolean = false;
  public pyPiModuleLoadById: boolean = false;
  @ViewChild('pyPiModuleAutocompleteInput')
  pyPiModuleAutocompleteInput!: ElementRef;
  private pyPiPackages!: any;
  // ─────────────────────────────────────────────────────────────────────────────

  // Url input
  @ViewChild('urlInput') urlInput!: ElementRef;

  public isGlobal: boolean = true;

  public sourceMode: string = 'url';

  public jsDelivrValue!: Version;

  public piPyValue: any;

  public sources: any[] = [
    {
      value: 'url',
      viewValue: 'Direct URL',
    },
    {
      value: 'from_cdn_js',
      viewValue: 'CDN js',
    },
    {
      value: 'from_js_delivr',
      viewValue: 'JSDelivr',
    },
    {
      value: 'py_pi',
      viewValue: 'PyPI',
    },
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Dialog,

    private messageService: MessageService,
    private cdnModuleService: CdnModuleService,
    private toastr: ToastrService
  ) {}

  ngAfterViewInit(): void {
    this.initAutocomplete();
  }

  // eslint-disable-next-line
  ngOnDestroy(): void {
    // AutoUnsubscribe
  }

  public initAutocomplete() {
    this.prepareCdnModuleAutocomplete();
    this.prepareJsdelivrModuleAutocomplete();
    this.preparePyPiModuleAutocomplete();
  }

  public async processData() {
    let scriptUrl: string = '';

    switch (this.sourceMode) {
      case 'url':
        if (
          this.urlInput.nativeElement.value !== '' &&
          this.isValidUrl(this.urlInput.nativeElement.value)
        ) {
          scriptUrl = this.urlInput.nativeElement.value;
        }
        break;

      case 'from_cdn_js':
        if (
          this.cdnModuleAutocompleteInput.nativeElement.value !== '' &&
          this.isValidUrl(this.cdnModuleAutocompleteInput.nativeElement.value)
        ) {
          scriptUrl = this.cdnModuleAutocompleteInput.nativeElement.value;
        }
        break;

      case 'from_js_delivr':
        if (
          this.jsdelivrModuleAutocompleteInput.nativeElement.value !== '' &&
          this.isValidUrl(
            this.jsdelivrModuleAutocompleteInput.nativeElement.value
          )
        ) {
          scriptUrl = this.processJsDelivrUrl();
        }
        break;

      case 'py_pi':
        if (this.pyPiModuleAutocompleteInput.nativeElement.value !== '') {
          try {
            scriptUrl = await this.processPythonUrl();
          } catch (error) {
            console.error('PyPi error:', error);
            this.toastr.warning(
              "Selected Python package doesn't have '.whl' file",
              'PyPi issue'
            );
          }
        }
        break;
      default:
        break;
    }

    this.messageService.sendMessage(CLOSE_URL_DIALOG, {
      url: scriptUrl,
      addToModules: this.isGlobal,
    });
  }

  private isValidUrl(url: string): boolean {
    return VALID_URL.test(url);
  }

  public updateIsGlobal($event: any) {
    this.isGlobal = $event.checked;
  }

  // ─────────────────────────────────────────────────────────────────────
  // Cdn Autocomplete

  private prepareCdnModuleAutocomplete() {
    if (this.cdnModuleAutocompleteInput === undefined) {
      return;
    }
    this.cdnModuleAutocompleteData = fromEvent(
      this.cdnModuleAutocompleteInput.nativeElement,
      'keyup'
    ).pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged() as any,
      switchMap(() => {
        return this.getCdnModule(
          this.cdnModuleAutocompleteInput.nativeElement.value
        );
      })
    );
  }

  private getCdnModule(val: string): Observable<any[]> {
    if (typeof val !== 'string' || val === '') {
      return EMPTY;
    }
    this.noCdnModuleFound = false;
    return this.cdnModuleService.getFromCdnJs(val).pipe(
      map((response: CdnJsModule | any) =>
        response.results.filter((option: any) => {
          return option?.latest?.toLowerCase().indexOf(val.toLowerCase()) >= 0;
        })
      ),
      tap((cdnModule: CdnJsModule[]) => {
        if (cdnModule.length === 0) {
          this.noCdnModuleFound = true;
        }
      })
    );
  }

  public displayCdnModule(cdnModuleResult: CdnJsModuleResult) {
    if (typeof cdnModuleResult === 'object' && cdnModuleResult !== null) {
      return `${cdnModuleResult.latest ? cdnModuleResult.latest : 'No name'}`;
    } else {
      return '';
    }
  }

  // ─────────────────────────────────────────────────────────────────────
  // Js Delivr Autocomplete

  private prepareJsdelivrModuleAutocomplete() {
    if (this.jsdelivrModuleAutocompleteInput === undefined) {
      return;
    }
    this.jsdelivrModuleAutocompleteData = fromEvent(
      this.jsdelivrModuleAutocompleteInput.nativeElement,
      'keyup'
    ).pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged() as any,
      switchMap(() => {
        return this.getJsdelivrModule(
          this.jsdelivrModuleAutocompleteInput.nativeElement.value
        );
      })
    );
  }

  private getJsdelivrModule(val: string): Observable<any[]> {
    if (typeof val !== 'string' || val === '') {
      return EMPTY;
    }
    this.noJsdelivrModuleFound = false;
    return this.cdnModuleService.getFromJsDelivr(val).pipe(
      map((response: JsDelivrModuleResult | any) => response.versions),
      tap((cdnModule: CdnJsModule[]) => {
        if (cdnModule.length === 0) {
          this.noJsdelivrModuleFound = true;
        }
      })
    );
  }

  public displayJsdelivrModule(cdnModuleResult: Version) {
    if (typeof cdnModuleResult === 'object' && cdnModuleResult !== null) {
      return `${
        cdnModuleResult.links.self ? cdnModuleResult.links.self : 'No name'
      }`;
    } else {
      return '';
    }
  }

  public setJsDelivrValue($event: any) {
    this.jsDelivrValue = $event?.option?.value;
  }

  private processJsDelivrUrl() {
    const nameAndVersion: string[] = this.jsDelivrValue?.links?.self
      ?.split('/')
      .slice(-1);
    return `https://cdn.jsdelivr.net/npm/${nameAndVersion[0]}`;
  }

  // ─────────────────────────────────────────────────────────────────────
  // Python PyPI Autocomplete

  private preparePyPiModuleAutocomplete() {
    if (this.pyPiModuleAutocompleteInput === undefined) {
      return;
    }

    this.pyPiModuleAutocompleteData = fromEvent(
      this.pyPiModuleAutocompleteInput.nativeElement,
      'keyup'
    ).pipe(
      startWith(''),
      debounceTime(400),
      distinctUntilChanged() as any,
      switchMap(() => {
        return this.getPyPiModule(
          this.pyPiModuleAutocompleteInput.nativeElement.value
        );
      })
    );
  }

  private filterPackages(val: string) {
    return this.pyPiPackages?.filter((pyPiPackage: any) =>
      pyPiPackage.name.toLowerCase().includes(val)
    );
  }

  private getPyPiModule(val: string): Observable<any[]> {
    if (typeof val !== 'string' || val === '') {
      return EMPTY;
    }

    this.noPyPiModuleFound = false;

    if (!this.pyPiPackages) {
      return this.cdnModuleService.getFromPyPi().pipe(
        map((response: any) => {
          if (response?.projects?.length === 0) {
            this.noPyPiModuleFound = true;
          }

          this.pyPiPackages = response?.projects;

          return this.filterPackages(val);
        })
      );
    }

    return new BehaviorSubject(this.filterPackages(val)).asObservable();
  }

  public displayPyPiModule(cdnModuleResult: any) {
    let moduleName = '';

    if (cdnModuleResult !== null) {
      moduleName = `${cdnModuleResult.name || 'No name'}`;
    }

    return moduleName;
  }

  public setPyPiValue($event: any) {
    this.piPyValue = $event?.option?.value?.name;
  }

  private async processPythonUrl(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.cdnModuleService.getFromPyPi(this.piPyValue).subscribe({
        next: (response) => {
          const file = response?.files?.findLast((file: any) =>
            file.url.endsWith('.whl')
          );

          if (file?.url) {
            resolve(file.url);
          } else {
            reject();
          }
        },
        error: () => {
          reject();
        },
      });
    });
  }

  // ─────────────────────────────────────────────────────────────────────
}
