import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_BASE } from '../../constants/general.constants';
import {
  CreateSecretRequest,
  CreateSecretSourceRequest,
  Secret,
  SecretSource,
  UpdateSecretSourceRequest,
  UpdateSecretRequest,
} from '../../interfaces/secrets.interface';

@Injectable({
  providedIn: 'root',
})
export class SecretsService {
  constructor(private http: HttpClient) {}

  private createSecretsUrl(
    projectId?: string | number,
    resource: 'sources' | 'entries' = 'sources',
    itemId?: string | number,
    subResource?: string
  ): string {
    let url = `${API_BASE}project`;

    if (projectId !== undefined) {
      url += `/${projectId}/secrets/${resource}`;
    }

    if (itemId !== undefined) {
      url += `/${itemId}`;
    }

    if (subResource) {
      url += `/${subResource}`;
    }

    return url;
  }

  // Secret Sources API methods

  /**
   * Get all secret sources for a project
   */
  getSecretSources(projectId: number): Observable<SecretSource[]> {
    return this.http.get<SecretSource[]>(
      this.createSecretsUrl(projectId, 'sources')
    );
  }

  /**
   * Create a new secret source
   */
  createSecretSource(
    projectId: number,
    source: CreateSecretSourceRequest
  ): Observable<SecretSource> {
    return this.http.post<SecretSource>(
      this.createSecretsUrl(projectId, 'sources'),
      source
    );
  }

  /**
   * Get a specific secret source
   */
  getSecretSource(
    projectId: number,
    sourceId: number
  ): Observable<SecretSource> {
    return this.http.get<SecretSource>(
      this.createSecretsUrl(projectId, 'sources', sourceId)
    );
  }

  /**
   * Update a secret source
   */
  updateSecretSource(
    projectId: number,
    sourceId: number,
    update: UpdateSecretSourceRequest
  ): Observable<SecretSource> {
    return this.http.patch<SecretSource>(
      this.createSecretsUrl(projectId, 'sources', sourceId),
      update
    );
  }

  /**
   * Delete a secret source
   */
  deleteSecretSource(projectId: number, sourceId: number): Observable<void> {
    return this.http.delete<void>(
      this.createSecretsUrl(projectId, 'sources', sourceId)
    );
  }

  // Secrets API methods

  /**
   * Get all secrets for a project
   */
  getSecrets(projectId: number): Observable<Secret[]> {
    return this.http.get<Secret[]>(this.createSecretsUrl(projectId, 'entries'));
  }

  /**
   * Create a new secret
   */
  createSecret(
    projectId: number,
    secret: CreateSecretRequest
  ): Observable<Secret> {
    return this.http.post<Secret>(
      this.createSecretsUrl(projectId, 'entries'),
      secret
    );
  }

  /**
   * Update a secret
   */
  updateSecret(
    projectId: number,
    secretId: number,
    update: UpdateSecretRequest
  ): Observable<Secret> {
    return this.http.patch<Secret>(
      this.createSecretsUrl(projectId, 'entries', secretId),
      update
    );
  }

  /**
   * Delete a secret
   */
  deleteSecret(projectId: number, secretId: number): Observable<void> {
    return this.http.delete<void>(
      this.createSecretsUrl(projectId, 'entries', secretId)
    );
  }

  /**
   * Get the value of a secret
   */
  getSecretValue(
    projectId: number,
    secretId: number
  ): Observable<{ value: string }> {
    return this.http.get<{ value: string }>(
      this.createSecretsUrl(projectId, 'entries', secretId, 'value')
    );
  }
}
