import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';
import { ProjectService } from '../../services/project/project.service';
import { ChunkTypeService } from '../../services/chunk-type/chunk-type.service';
import { ProjectCreate } from '../../interfaces/project.interface';
import { AppStateService } from '../../services/app-state/app-state.service';
import {
  FeatureService,
  Feature,
} from '../../services/feature/feature.service';
import { debounce } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { firstValueFrom } from 'rxjs';
import { MatSelectionListChange } from '@angular/material/list';
import { AutoUnsubscribe } from '../../decorators/auto-unsubscribe.decorator';

@AutoUnsubscribe()
@Component({
  selector: 'app-add-project-dialog',
  templateUrl: './add-project-dialog.component.html',
  styleUrls: ['./add-project-dialog.component.scss'],
})
export class AddProjectDialogComponent implements OnInit, OnDestroy {
  public chunkTypeList: any[] = [];
  public features: Feature[] = [];
  public selectedFeatures: number[] = [];

  projectForm = this._formBuilder.group({
    name: ['', Validators.required],
    description: ['', Validators.required],
    notebookName: ['', Validators.required],
    chunkName: ['', Validators.required],
    chunkTypeId: [null, Validators.required],
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _formBuilder: FormBuilder,
    private projectService: ProjectService,
    private chunkTypeService: ChunkTypeService,
    private featureService: FeatureService,
    private dialogRef: MatDialogRef<AddProjectDialogComponent>,
    private appStateService: AppStateService,
    private toastr: ToastrService
  ) { }

  ngOnInit() {
    this.chunkTypeService.chunkTypes$.subscribe((types: any) => {
      this.chunkTypeList = types;
    });

    this.featureService.getFeatures().subscribe(
      (features) => {
        this.features = features;
      },
      (error) => {
        console.error('Failed to load features:', error);
        this.toastr.error('Failed to load features');
      }
    );
  }

 // eslint-disable-next-line
 ngOnDestroy() {
    // This is intentional
  }

  onFeatureSelectionChange(event: MatSelectionListChange): void {
    this.selectedFeatures = event.source.selectedOptions.selected
      .map((option) => {
        const feature = this.features.find(
          (f) => f.featureId === option.value
        );
        return feature ? feature.featureId : null;
      })
      .filter((id): id is number => id !== null);
  }

  public submitForm = debounce(
    async (formValue: any, isValid: boolean, $event: Event) => {
      $event.preventDefault();

      if (!isValid) {
        return;
      }

      const {
        value: { name, description, notebookName, chunkName, chunkTypeId },
      } = formValue;

      const projectData: ProjectCreate = {
        name: String(name).trim(),
        description: String(description).trim(),
        notebookName: String(notebookName).trim(),
        chunkName: String(chunkName).trim(),
        chunkTypeId: Number(chunkTypeId),
      };

      try {
        // Create project first
        const createdProject = await firstValueFrom(
          this.projectService.create(projectData)
        );

        // Add selected features if any are selected
        if (this.selectedFeatures.length > 0) {
          for (const featureId of this.selectedFeatures) {
            try {
              await firstValueFrom(
                this.projectService.addProjectFeature(
                  createdProject.projectId as number,
                  featureId
                )
              );
            } catch (featureError) {
              console.error(
                `Failed to add feature ${featureId}:`,
                featureError
              );
              this.toastr.warning(`Failed to add feature to the project`);
            }
          }
        }

        await this.appStateService.loadProjects();

        if (createdProject.projectId && createdProject.currentVersionId) {
          this.appStateService.navigateToProject(
            createdProject.projectId,
            createdProject.currentVersionId
          );
        }

        this.dialogRef.close();
        this.toastr.success('Project created successfully');
      } catch (error) {
        console.error('Failed to create project:', error);
        this.toastr.error('Failed to create project');
      }
    },
    500
  );
}
