import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tableFromIPC } from 'apache-arrow';
import { fromArrow } from 'arquero';
import { Papa } from 'ngx-papaparse';
import { ConvertService } from '../convert/convert.service';
import { GeneralHelpers } from '../../helpers/general.helper';
import { FileHelpers } from '../../helpers/file.helper';
import { RenderData } from '../../interfaces/table-result.interface';
import { ChunkContext } from '../../interfaces/chunk/chunk-context.interface';
import {
  ERROR_DISPLAY_IMAGE,
  ERROR_DISPLAY_TABLE,
} from '../../constants/additional-methods.constants';
import { MessageService } from '../message/message.service';
import { RUN_CHUNK, SCROLL_TO_CHUNK } from '../../constants/general.constants';
import { ChunkService } from '../chunk/chunk.service';

@Injectable({
  providedIn: 'root',
})
export class RenderService {
  public renderList: RenderData[] = [];
  public renderServiceSubject$ = new Subject<RenderData>();

  constructor(
    private papa: Papa,
    private convertService: ConvertService,
    private messageService: MessageService,
    private chunkService: ChunkService
  ) { }

  public addToRenderList(data: RenderData): void {
    const { value, chunkId, type } = data;
    const renderItem = { value, chunkId, type };
    this.renderServiceSubject$.next(renderItem);
  }

  public renderVideo(args: any[], context: ChunkContext) {
    if (!Array.isArray(args) || args.length === 0) {
      context.addMessage('Error displaying video', 'danger');
      return;
    }

    const video = args[0];
    const chunkData = context.getChunk();

    if (video === null || video === undefined) {
      context.addMessage('Error displaying video', 'danger');
      return;
    }

    if (typeof video === 'string' && this.isValidUrl(video)) {
      const youtubeRegex =
        /^(https?:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/;
      if (youtubeRegex.test(video)) {
        this.addToRenderList({
          value: video,
          chunkId: chunkData.chunkId,
          type: 'youtube',
        });
        return;
      }
      this.addToRenderList({
        value: video,
        chunkId: chunkData.chunkId,
        type: 'video',
      });
      return;
    }

    if (
      typeof video === 'string' &&
      GeneralHelpers.canBeParsedToNumberArray(video)
    ) {
      const numberArray = video.split(',').map(Number);
      const videoBuffer = new Uint8Array(numberArray);
      const videoBlob = new Blob([videoBuffer], { type: 'video/mp4' });
      const videoUrl = URL.createObjectURL(videoBlob);
      this.addToRenderList({
        value: videoUrl,
        chunkId: chunkData.chunkId,
        type: 'video',
      });
      return;
    }

    context.addMessage('Invalid video data', 'danger');
  }

  private isValidUrl(str: string): boolean {
    try {
      new URL(str);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  public async renderTable(args: any[], context: ChunkContext) {
    const chunkData = context.getChunk();
    let tableData = args[0];

    if (
      !chunkData ||
      !chunkData.chunkId ||
      args === null ||
      args === undefined
    ) {
      context.addMessage(ERROR_DISPLAY_TABLE, 'danger');
      return;
    }

    try {
      if (typeof tableData === 'string') {
        const fileType: string =
          FileHelpers.detectFileTypeFromByteString(tableData);

        switch (fileType) {
          case 'CSV':
            tableData = await this.processByteStringToCsv(tableData);
            break;
          case 'JSON':
            tableData = this.processJsonData(tableData);
            break;
          case 'Arrow':
          case 'Parquet':
            tableData = await this.processArrowOrParquetData(
              tableData,
              fileType
            );
            break;
          default:
            if (GeneralHelpers.canBeParsedToNumberArray(tableData)) {
              tableData = await this.processByteStringToCsv(tableData);
            } else {
              throw new Error('Unknown string data type');
            }
        }
      } else if (typeof tableData === 'object') {
        if (GeneralHelpers.isArrowTable(tableData)) {
          tableData = await this.processArrowTable(tableData);
        } else {
          tableData = this.processObjectData(tableData);
        }
      } else {
        throw new Error('Unsupported data type');
      }

      const formattedData = this.formatDataForTableComponent(tableData);
      this.addToRenderList({
        value: formattedData,
        chunkId: chunkData.chunkId,
        type: 'table',
      });
    } catch (error: any) {
      context.addMessage(
        `Error processing table data: ${error.message}`,
        'danger'
      );
    }
  }

  private async processByteStringToCsv(data: string): Promise<any> {
    let csvString: string;

    if (GeneralHelpers.canBeParsedToNumberArray(data)) {
      const numberArray = data.split(',').map(Number);
      const uint8Array = new Uint8Array(numberArray);
      csvString = new TextDecoder().decode(uint8Array);
    } else {
      csvString = data; // Assume it's already a CSV string
    }

    return new Promise((resolve, reject) => {
      this.papa.parse(csvString, {
        complete: (results) => {
          if (results.errors.length > 0) {
            reject(
              new Error('CSV parsing error: ' + results.errors[0].message)
            );
          } else {
            resolve(results.data);
          }
        },
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
      });
    });
  }

  private processJsonData(data: string): any {
    const json = GeneralHelpers.jsonParse(data);
    return Array.isArray(json) ? json : [json];
  }

  private async processArrowOrParquetData(
    data: string,
    fileType: string
  ): Promise<any> {
    const numberArray = data.split(',').map(Number);
    let arrowBuffer = new Uint8Array(numberArray);

    if (fileType === 'Parquet') {
      arrowBuffer = await this.convertService.parquetToArrow([data]);
    }

    const arrowTable = tableFromIPC(arrowBuffer);
    return this.processArrowTable(arrowTable);
  }

  private async processArrowTable(arrowTable: any): Promise<any> {
    const arqueroTable = fromArrow(arrowTable);

    try {
      const tableJson = arqueroTable.toJSON();
      return GeneralHelpers.jsonParse(tableJson);
    } catch (error) {
      const csvData = arqueroTable.toCSV();
      console.error('Error converting Arrow table to JSON:', error);
      return this.processByteStringToCsv(csvData);
    }
  }

  private processObjectData(data: any): any {
    return Array.isArray(data) ? data : [data];
  }

  private formatDataForTableComponent(data: any): any {
    let formattedData: any[] = [];
    let columns: any[] = [];

    if (data.schema && data.data) {
      // Handle Arrow-like format
      const fieldNames = data.schema.fields.map((field: any) => field.name);
      const rowCount = data.data[fieldNames[0]].length;

      for (let i = 0; i < rowCount; i++) {
        const row: any = {};
        fieldNames.forEach((fieldName: string) => {
          row[fieldName] = data.data[fieldName][i];
        });
        formattedData.push(row);
      }

      columns = fieldNames.map((fieldName: string) => ({
        name: `${fieldName} (${typeof formattedData[0][fieldName]})`,
        dataKey: fieldName,
        isSortable: true,
      }));
    } else if (Array.isArray(data)) {
      // Handle array of objects
      formattedData = data;
      if (formattedData.length > 0) {
        columns = Object.keys(formattedData[0]).map((key) => ({
          name: `${key} (${typeof formattedData[0][key]})`,
          dataKey: key,
          isSortable: true,
        }));
      }
    } else if (typeof data === 'object') {
      // Handle single object
      formattedData = [data];
      columns = Object.keys(data).map((key) => ({
        name: `${key} (${typeof data[key]})`,
        dataKey: key,
        isSortable: true,
      }));
    } else {
      console.error('Unsupported data format');
      return { data: [], columns: [] };
    }

    return {
      data: formattedData,
      columns: columns,
    };
  }

  public displayImageGroup(args: any[], context: ChunkContext) {
    if (!Array.isArray(args) || args.length === 0) {
      context.addMessage('Error displaying image group', 'danger');
      return;
    }
    try {
      const imageGroup = JSON.parse(args[0]);
      if (
        !imageGroup ||
        typeof imageGroup !== 'object' ||
        imageGroup.kind !== 'imageGroup'
      ) {
        throw new Error('Invalid image group data');
      }
      context.showImageGroup(imageGroup);
    } catch (error: any) {
      context.addMessage(
        `Error displaying image group: ${error.message}`,
        'danger'
      );
    }
  }

  public renderImage(args: any[], context: ChunkContext) {
    if (!Array.isArray(args) || args.length === 0) {
      context.addMessage(ERROR_DISPLAY_IMAGE, 'danger');
      return;
    }

    const image = args[0];
    const chunkData = context.getChunk();

    if (!chunkData || !chunkData.chunkId) {
      context.addMessage(ERROR_DISPLAY_IMAGE, 'danger');
      return;
    }

    if (
      typeof image === 'string' &&
      GeneralHelpers.canBeParsedToNumberArray(image)
    ) {
      const numberArray = image.split(',').map(Number);
      const imageBuffer = new Uint8Array(numberArray);
      const imageBlob = new Blob([imageBuffer], { type: 'image/png' });
      const imageUrl = URL.createObjectURL(imageBlob);
      this.addToRenderList({
        value: imageUrl,
        chunkId: chunkData.chunkId,
        type: 'image',
      });
    } else if (image) {
      this.addToRenderList({
        value: image,
        chunkId: chunkData.chunkId,
        type: 'image',
      });
    } else {
      context.addMessage('Invalid image data', 'danger');
    }
  }

  public goToBlock(data: any[], context: ChunkContext) {
    const chunkName = data[0];
    if (!chunkName) {
      context.addMessage('Invalid block name', 'danger');
      return;
    }
    const chunk = this.chunkService.getChunkByName(chunkName);
    if (chunk === null) {
      context.addMessage('Block not found', 'danger');
      return;
    }
    const shouldRun = data[1];
    this.messageService.sendMessage(SCROLL_TO_CHUNK, chunk);
    if (shouldRun) {
      this.messageService.sendMessage(RUN_CHUNK, chunk);
    }
  }

  public log(
    data: any[],
    context: ChunkContext,
  ) {
    context.addLog(data[0], data[1]);
  }

  public output(
    data: any[],
    context: ChunkContext,
    typeRunner: 'javascript' | 'python'
  ) {
    context.addOutput(data[0], data[1], typeRunner);
  }
}
