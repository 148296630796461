import { computed, inject, Injectable, signal } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AppStateService } from '../app-state/app-state.service';
import { API_BASE, PROJECT } from '../../constants/general.constants'
import { DBAlias } from '../../interfaces/db-alias.interface'
import { catchError, Observable, tap, throwError } from 'rxjs'


@Injectable({
  providedIn: 'root'
})
export class DBAliasService {
  #httpClient = inject(HttpClient);
  #appState = inject(AppStateService);

  baseUrl = computed(() => `${API_BASE}${PROJECT}/${this.#appState.projectId()}/dbalias`);

  #aliases = signal<DBAlias[]>([]);
  aliases = this.#aliases.asReadonly();


  constructor() {
    this.refreshAliases().finally();
  }

  async refreshAliases(): Promise<DBAlias[]> {
    const url = this.baseUrl();
    this.#httpClient.get<DBAlias[]>(url)
      .subscribe((data) => {
        this.#aliases.set(data);
      });
    return this.#aliases();
  }

  duplicateAlias(aliasId: number): Observable<DBAlias> {
    const url = this.baseUrl() + `/${aliasId}/duplicate`;
    return this.#httpClient.put<DBAlias>(url, {}).pipe(
      tap((data) => {
        this.#aliases.set([...this.#aliases(), data]);
      })
    );
  }

  deleteAlias(aliasId: number): Observable<any> {
    const url = this.baseUrl() + `/${aliasId}`;
    return this.#httpClient.delete<any>(url).pipe(
      tap(() => {
        this.#aliases.set(this.#aliases().filter((a) => a.dbAliasId !== aliasId));
      }),
      catchError(this.handleError)
    );
  }

  saveAlias(alias: Partial<DBAlias>): Observable<DBAlias> {
    const url = this.baseUrl() + `/${alias.dbAliasId}`;
    return this.#httpClient.put<DBAlias>(url, alias).pipe(
      tap((data) => {
        const aliases = this.#aliases();
        const idx = aliases.findIndex((a) => a.dbAliasId === alias.dbAliasId);
        if (idx >= 0) {
          aliases[idx] = data;
          this.#aliases.set(aliases);
        }
      }),
      catchError(this.handleError)
    );
  }

  createAlias(alias: Partial<DBAlias>): Observable<DBAlias> {
    const url = this.baseUrl();
    return this.#httpClient.post<DBAlias>(url, alias).pipe(
      tap((data) => {
        this.#aliases.set([...this.#aliases(), data]);
      }),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }
}
